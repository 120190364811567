@import "../../assets/styles/colors";
@import "../../assets/styles/measures";
@import "../../assets/styles/mediaqueries";


.Modal-container{
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.Modal{
  width: 800px;
  z-index: 45;
  padding: $items-space;
  border-radius: $element-borderRadius;
  background:$davinci-grey-gradient;
  color: white;
  min-height: 270px;
  margin: auto;
  max-height: 90%;
  overflow-y: auto;

  &-overlay{
    background-color: rgba(0,0,0,.75);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 43;
  }

  &-closeCross{
    width: 30px;
    height: 30px;
    background-color: transparent;
    position: absolute;
    top: 10px;
    right:10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      width: 80%;
      height: 1px;
      background-color: $davinci-white;
      transform: rotate(45deg);
    }
    &::after{
      content: '';
      position: absolute;
      width: 80%;
      height: 1px;
      background-color: $davinci-white;
      transform: rotate(-45deg);
    }
  }
  &-title{
    height: 7%;
    margin-bottom: $items-space;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $davinci-blue;
    color: $davinci-white;
    padding: $items-space/2 $items-space;
    border-radius: $element-borderRadius;
    position: relative;
    & p{
      font-size: $root-font-size*1.1;
      font-family: Montserrat-bold,sans-serif;
    }
  }
  &-template{
    width: 100%;
    height: 90%;
  }
}

@import 'src/assets/styles/colors';
.TicketsList {
  .header, .ticket {
    vertical-align: middle;
    &>div{
      display: inline-block;
      width: 16.6%;
    }
  }
  .header{
    border-bottom: 1px solid;
    border-color: $davinci-mainOrange;
    border-radius: 0;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .tickets-container{
    height: 300px;
    overflow: scroll;
  }
  .ticket{
    display: flex;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid;
    border-color: $davinci-lightOrange;
  }
}
@import "../../../../../../assets/styles/colors";
@import "../../../../../../assets/styles/measures";
@import "../../../../../../assets/styles/mediaqueries";

.MenuAmountSelect{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -100px;
  left: -2px;
  background-color: $davinci-blue;
  border-radius: 100px;
  height: 40px;
  width: 40px;
  transition:bottom .2s;
  &.openMenuAmountSelect{
    bottom: -2px;
  }
  &-add,&-sub{
    width: 33.333%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    outline: none;
    & svg{
      width: 15px;
      height: 15px;
      & > *{
      stroke:$davinci-white;
        fill: $davinci-white;
    }
    }
  }
  &-text{
    display: flex;
    justify-content: center;
    align-items: center;
    color: $davinci-white;
    width: 33.333%;
    height: 100%;
    & p{
      font-size: $root-font-size*1.2;
    }
  }
}
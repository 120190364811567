@import "../../../../assets/styles/colors";
@import "../../../../assets/styles/measures";
@import "../../../../assets/styles/mediaqueries";

.NearToExpire{
  width: 100%;
  background:$davinci-mutedOrange;
  padding: $items-space;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: $element-borderRadius;
  height: calc(100% / 3);
  position: relative;
  @include responsive(tablet){
    width: 32%;
    height: 100%;
  }
  &-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: $root-font-size*1.2;
    font-family: Montserrat-semibold,sans-serif;
    border: 1px solid $davinci-mainOrange;
    border-radius: $element-borderRadius;
    padding:$items-space/2;
    color: $davinci-white;
    position: absolute;
    top: 20px;
    left: 50%;
    width: 90%;
    transform: translate(-50%, 0);
    &-input{
      height: 100%;
      width: 100%;
      background-color: transparent;
      font-size:  $root-font-size*1.2;
      font-family: Montserrat-medium,sans-serif;
      border: none;
      outline: none;
      color: $davinci-white;
      cursor: pointer;
      padding-right: $items-space;
      &::placeholder{
        color: $davinci-white;
        font-family: Montserrat-semibold,sans-serif;
      }
      &:focus::placeholder{
        color: $davinci-lightWhite;
      }
    }
     svg{
      position: absolute;
      right: 8px;
      pointer-events: none;
        & path{
        fill: $davinci-white;
      }
    }
  }
  &-noInfo{
    text-align: center;
    color: $davinci-lightWhite;
    font-size: 1.5rem;
  }
  &-content{
    overflow-y: scroll;
    margin-top: $items-space*2.5;
    padding-right: 5px;
    width: 100%;
  }
  &-list{
    width: 100%;
    color: $davinci-white;
    &-item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-size: $root-font-size/1.05;
      list-style: none;
      padding: $items-space/2 0 $items-space/5 0;
      .Button{
        padding:4px 6px;
      }
      @include responsive(big-laptop){
        flex-direction: column;
        align-items: unset;
      }
    }
    &-text{
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include responsive(big-laptop){
        margin-bottom: $items-space/2;
      }
      p.name{
        white-space: nowrap;
        width: 170px;
        text-overflow: ellipsis;
        margin-right: 10px;
        overflow: hidden;
      }
      &:not(:last-child){
        border-bottom: 1px solid $davinci-mainOrange;
        padding: 0 0 $items-space/5 $items-space/2;
      }
    }
  }
}

@import "../../../../assets/styles/colors";
@import "../../../../assets/styles/measures";
@import "../../../../assets/styles/mediaqueries";

.SimilarResults{
  height: 100%;
  position: relative;

  .result-count{
    text-align: center;
    opacity: 0.7;
    margin: 0;
  }
  &-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $davinci-blue;
    color: $davinci-white;
    padding: $items-space/2 $items-space;
    border-radius: $element-borderRadius;
    margin-bottom: $items-space;
    position: relative;
    height: $boxBar-height;
    & p{
      font-size: $root-font-size*1.4;
      font-family: Montserrat-bold,sans-serif;
      @include responsive(big-laptop){
        font-size: $root-font-size/.9;
      }
      @include responsive(regular-laptop){
        font-size: $root-font-size;
      }
      @include responsive(small-laptop){
        font-size: $root-font-size/1.1;
      }
    }
  }
  &-checked{
    background-color: $davinci-lightWhite;
  }
  &-content{
    margin-top: $items-space;
    padding-right: 5px;
    height: 90%;
    overflow-y: scroll;
  }
  &-list{
    width: 100%;
    color: $davinci-white;
    list-style: none;
    &-checkIcon{
      position: absolute;
      transform: translate(-50%,-50%);
      top: 50%;
      right: 0;
    }
    &-item{
      display: flex;
      flex-direction: column;
      cursor: pointer;
      padding: $items-space/2;
      position: relative;
      & svg{
        & path{
          fill: $davinci-white;
          stroke: $davinci-white;
        }
      }
      &:not(:last-child){
        border-bottom: 1px solid $davinci-blue;
      }
    }
  }
  .loading-container{
    text-align: center;
    padding-top: 28px;
    .LoadingAnimation{
      display: inline-block;
    }
  }
  .no-results{
    text-align: center;
    font-weight: bold;
    font-size: 1.6em;
    margin-top: 100px;
    color: #4a4a4a;
  }
  .SimilarResults-list-email{
    margin-top: 10px;
    font-size: 12px;
    color:grey;
  }
}


@import "../../../../../assets/styles/colors";
@import "../../../../../assets/styles/measures";

.CheckInInfo{
  width: 100%;
  background:$davinci-black-gradient;
  padding: $items-space/2;
  display: flex;
  flex-direction: column;
  color:$davinci-black;
  border-radius: $element-borderRadius;
  height: 55%;
  margin-bottom: $items-space;
  &-accessMethod{
    & p{
      text-align: center;
    }
  }
  &-checkInInfoCard{
    height: 80%;
    overflow-y: scroll;
  }
  &-title{
    padding: $items-space/2;
    display: grid;
    display: -ms-grid;
    grid-template-columns:35% 25% 25% 15%;
    -ms-grid-columns: 35% 25% 25% 15%;
    color: $davinci-mutedWhite;
    font-size: $root-font-size*.7;
    margin-bottom: $items-space/2;
  }
  &-column{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

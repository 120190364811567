@import "../../../../assets/styles/colors";
@import "../../../../assets/styles/measures";
@import "../../../../assets/styles/mediaqueries";

.TopBar{
  &-time{
    color: $davinci-white;
    display: flex;
    align-items: center;
    & p{
      font-size: $root-font-size*1.2;
      font-family: 'Montserrat-bold',sans-serif;
      @include responsive(big-laptop){
        font-size: $root-font-size;
      }
    }
  }
}

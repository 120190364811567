@import "../../../../assets/styles/colors";
@import "../../../../assets/styles/measures";
@import "../../../../assets/styles/mediaqueries";

.NearReservations{
  width: 100%;
  background:$davinci-black-gradient;
  padding: $items-space;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: $element-borderRadius;
  height: calc((100% / 3) - (#{$items-space}));
  margin-bottom: $items-space;
  position: relative;
  @include responsive(tablet){
    width: 32%;
    height: 100%;
    margin-right: $items-space/2;
  }

  &-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: $root-font-size*1.2;
    font-family: Montserrat-semibold,sans-serif;
    border: 1px solid $davinci-mainOrange;
    border-radius: $element-borderRadius;
    padding:$items-space/2;
    color: $davinci-mainOrange;
    position: absolute;
    top: 20px;
    left: 50%;
    width: 90%;
    transform: translate(-50%, 0);
    &-input{
      height: 100%;
      width: 100%;
      background-color: transparent;
      font-size:  $root-font-size*1.2;
      font-family: Montserrat-medium,sans-serif;
      border: none;
      outline: none;
      color: $davinci-mainOrange;
      cursor: pointer;
      padding-right: $items-space;
        &::placeholder{
          color: $davinci-mainOrange;
          font-family: Montserrat-semibold,sans-serif;
        }
      &:focus::placeholder{
        color: $davinci-lightWhite;
      }
    }

    svg{
      position: absolute;
      right: 8px;
      pointer-events: none;
      path{
        fill: $davinci-mainOrange;
      }
    }
  }
  &-noInfo{
    text-align: center;
    color: $davinci-lightWhite;
    font-size: 1.5rem;
  }
  &-content{
    overflow-y: scroll;
    margin-top: $items-space*2.5;
    padding-right: 1rem;
    width: 100%;
  }

  &-list{
    width: 100%;
    color: $davinci-white;
    &-item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-size: $root-font-size/1.05;
      list-style: none;
      padding: $items-space/2 $items-space $items-space/5 $items-space/2;
      cursor: pointer;

      &:not(:last-child){
        border-bottom: 1px solid $davinci-mainOrange;
      }
    }
  }
  .sync-button{
    position: absolute;
    bottom: 5px;
    right: 5px;
    border: none;
    background: none;
    color: $davinci-lightWhite;
    font-size: 1.2em;
    cursor: pointer;
  }
}

@import "../../../../../assets/styles/colors";
@import "../../../../../assets/styles/measures";

.NumberOfVisits {
  width: 100%;
  background-color: $davinci-mutedWhite;
  padding: $items-space;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #060607;
  border-radius: 11px;
  height: calc((100% / 8) - 20px);
  margin-bottom: 20px;
  position: relative;

  &-text {
    font-family: 'Montserrat-semibold', sans-serif;
    font-size: $root-font-size*2;
    color: $davinci-white;
    letter-spacing: 1px;
  }
  &-title{
    font-family: 'Montserrat-semibold', sans-serif;
    font-size: $root-font-size;
    color: $davinci-white;
    letter-spacing: 1px;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left:50px;
  }
}
@import "../../../../assets/styles/colors";
@import "../../../../assets/styles/measures";
@import "../../../../assets/styles/mediaqueries";


.LogOut{
  cursor: pointer;

  border: 2px solid $davinci-mainOrange;
  border-radius:  $element-borderRadius;
  background-color: black;
  position: absolute;
  top:$items-space;
  left:0;
  right: -11px;
  display: flex;
  justify-content: center;
  transition: top .3s;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  z-index: 200;

  .log-out-text{
    margin: 12px 0;
  }
  &.animateOpen{
    top:$items-space * 2.6;
  }
  &-back-overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.1);
    z-index: 100;
  }
  &-plica{
    content: '';
    width: 11px;
    height: 11px;
    border-left: 2px solid #FF8902;
    border-top: 2px solid #FF8902;
    background-color: black;
    transform: rotate(45deg);
    position: absolute;
    top: -7px;
    right: 7px;
    z-index: 33;
    border-top-right-radius: 3px;
  }
}

@import "../../assets/styles/measures";
@import "../../assets/styles/colors";
@import "../../assets/styles/mediaqueries";


.KeyBoardTouch{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-cross{
    top: 25px;
    left: 25px;
  }
  &-input{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: $root-font-size*2;
    margin-bottom: $items-space;
  }
  &-sendBtn{
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: $items-space;
    cursor: pointer;
    background-color: transparent;
    border: none;
    transition: all .2s;
    outline: none;
    &:active{
      transform: scale(.92);
    }
  }
  &-text{
    width: 90%;
    border-radius: $element-borderRadius;
    padding:$items-space/2;
    background-color: transparent;
    color: $davinci-mainOrange;
    border:1px solid $davinci-mainOrange;
    font-size: $root-font-size*2;
    @include responsive(big-laptop){
      margin-bottom: $items-space/2;
    }
  }
  &-keys{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 350px;
    flex-wrap: wrap;
    @include responsive(big-laptop){
      justify-content: space-around;
      transform: scale(.95);
      height: 290px;
    }
  }
  &-numbers{
    width: 300px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include responsive(big-laptop){
      width: 250px;
      align-content: center;
    }
  }
  &-letters{
    width: 1200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include responsive(big-laptop){
      width: 950px;
    }
  }
  .key{
    border: 1px solid $davinci-mainOrange;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $davinci-white;
    font-size: $root-font-size*1.5;
    padding: $items-space/2;
    border-radius: $element-borderRadius;
    width: 80px;
    cursor: pointer;
    font-family: Montserrat-semibold,sans-serif;
    transition: all .2s;
    outline: none;
    &:active{
      transform: scale(.92);
    }
    &:not(:last-child){
      margin-right: $items-space;
    }
  }
  .space{
    width: 100%;
    height: 100%;
  }
  .backspace{
    width: 125px;
    & svg{
      width: 35px;
      height: 20px;
      & path{
        fill: $davinci-white;
      }
    }
  }
  .cero{
    width: 60%;
    margin-bottom: 0;
  }
  &-first-line,&-second-line,&-third-line{
    display: flex;
    justify-content: center;
    height: calc((100% / 4) - #{$items-space});
    margin-bottom: $items-space;
    width: 100%;
    @include responsive(big-laptop){
      margin-bottom: 10px;
      height: calc((100% / 3.5) - #{$items-space});
    }
  }
  &-second-line{
    @include responsive(big-laptop){
     width: 90%;
    }
  }
  &-space-bar{
    height: calc((100% / 4) - #{$items-space});
    width:90%;
  }
  &-numbersRow{
    width: 100%;
    height: calc((100% / 4) - #{$items-space});
    display: flex;
    @include responsive(big-laptop){
      margin-bottom: $items-space/2;
      height: calc((100% / 3.5) - #{$items-space});
    }
  }
}
.sendIcon{
  fill: $davinci-mainOrange;
}
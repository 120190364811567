
.AddServiceModal{
  min-height: 400px;
  .Modal-template {
    display: flex;
    justify-content: space-between;
  }


  .buttons{
    margin-top: 160px;
    display: flex;
    justify-content: space-around;
    .Button{
      font-size: 1.6em;
      min-width: 6em;
    }

  }
}

@font-face {
  font-family: "Montserrat-medium";
  src: url("./Montserrat/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "Montserrat-semibold";
  src: url("./Montserrat/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: "Montserrat-bold";
  src: url("./Montserrat/Montserrat-Bold.ttf");
}

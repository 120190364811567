@import '../../assets/styles/colors';
@import '../../assets/styles/measures';
@import '../../assets/styles/mediaqueries';

.Configuration{

  .config-block{
    width: 30%;
    margin-bottom: 10px;
    margin-right: 1.5%;
    margin-left: 1.5%;
    @include responsive(tablet){
      width: 45%;
      margin-right: $items-space;
      margin-bottom: $items-space;
    }
    .config-title{
      background-color: $davinci-blue;
      border-radius: $element-borderRadius;
      font-size: 1.6em;
      font-weight: bold;
      padding: 8px;
    }
    .config-content{
      background-color: $davinci-lightWhite;
      margin-top: 15px;
      border-radius: $element-borderRadius;
      text-align: center;
      padding: 15px;
    }
  }

  .main-content{
    height: calc(100vh - 150px);
    align-items: flex-start;
    width: 88%;
    margin: 0 auto;
    padding-bottom: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
}

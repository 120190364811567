@import "../../../../assets/styles/colors";
@import "../../../../assets/styles/measures";
@import "../../../../assets/styles/mediaqueries";

.NewReservation{
  max-width: 54%;
  width: 54%;
  height: 100%;
  border-radius: $element-borderRadius;

  .remote-disclaimer{
    text-align: center;
    padding: 0 10px 15px 10px;
    font-family: "Montserrat-bold", sans-serif;
    font-size: 1.2em;
  }

  &.full-width{
    width: 100%;
    max-width: 100%;
    padding: $items-space;
  }

  @include responsive(tablet){
    width: 72%;
    height: 70%;
    max-width: none;
    margin-bottom: $items-space;
  }
  &-reservation-notes{
      .reservation-notes{
          font-style: italic;
      }
      margin-bottom: $items-space;
  }
  &-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $davinci-blue;
    color: $davinci-white;
    padding: $items-space/2 $items-space;
    border-radius: $element-borderRadius;
    margin-bottom: $items-space;
    height: $boxBar-height;
    position: relative;
    & p{
      font-size: $root-font-size*1.4;
      font-family: Montserrat-bold,sans-serif;
    }
    & svg{
      & path{
        fill: $davinci-white;
        stroke: $davinci-white;
      }
    }
  }

  &-byDoc {
    margin-bottom: 20px;
    &-icon {
      margin-left: 15px;
    }
  }

  &-form{
    background: $davinci-black-gradient;
    border-radius: $element-borderRadius;
    padding: $items-space;
    color: $davinci-white;
    height: 90%;
    overflow-y: scroll;

    .SingleCheckInForm{
      padding: 10px;
      border-radius: $element-borderRadius;
      border: 1px solid $davinci-lightWhite;
      margin-bottom: $items-space*.6;;
    }
    &.no-height{
      height: initial;
    }

  }
  hr{
    border-color: #525252;
  }
  .clear-btn{
    display: inline-block;
    min-height: 48px;
  }

  .lounge-field{
    margin-bottom: 10px;
  }

  .form{
    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $items-space*.6;

      @media screen and (max-width: 600px){
        flex-direction: column;
      }

      &.centerItemInARow{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-single{
        &:not(:last-child){
          margin-bottom: $items-space*.4;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        & p{
          font-size: $root-font-size/1.05;
          margin-bottom: $items-space*.4;
        }
        &.centerItemInARow{
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    &-column{
      display: flex;
      flex-direction: column;
      justify-content: center;
      & p{
        font-size: $root-font-size/1.05;
        margin-bottom: $items-space*0.4;
      }
    }
  }
  &-fullWidth{
    max-width: 100%;
    width: 100%;
    height: 100%;
    border-radius: $element-borderRadius;
  }

  .timer{
    position: absolute;
    right: 71px;
    top: 12px;
    font-size: $root-font-size*1.4;
    font-family: Montserrat-bold,sans-serif;
  }
}
@import "../../assets/styles/colors";
@import "../../assets/styles/measures";

.FullscreenPicker{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0.5,0.5,0.5,0.5);
  color: white;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;


  .background{
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }
  div, button{
    color: white;
  }

  &-container {
    z-index: 2;
    background-color: black;
    box-shadow: 0 0 6px 2px rgba(255, 255, 255, 0.2);
    padding: 0 15px;

    max-height: 80vh;
    overflow-y: auto;

  }

  .button{
    width: 100%;
    min-height:60px;
    background-color: $davinci-lightBlue;
    border-radius: $element-borderRadius;
    padding: 5px;
    color: $davinci-white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $root-font-size*.85;
    text-align: center;
    cursor: pointer;
    position: relative;
    animation-duration: 500ms;
  }

}

.LoadingBar{
  position: fixed;
  top: 0;
  width: 100vw;
  height: 5px;
  background: linear-gradient(90deg, #ff8902 40%, #000000 45%, #3d79ff 100%);
  background-size: 200% 100%;
  z-index: 1000;
  animation: LoadingBarGradientAnimation 4s ease infinite;
}

@keyframes LoadingBarGradientAnimation {
  0%{background-position:0 51%}
  50%{background-position:100% 50%}
  100%{background-position:0 51%}
}

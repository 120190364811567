@import "../../assets/styles/colors";
@import "../../assets/styles/measures";
.CheckBoxContainer{
  width: 100%;
  height: 100%;
}
.Check {
  & input {
    display: none;
    &:checked + .Box {
      background-color: $davinci-mainOrange;
      &:after {
        top: -2px;
      }
    }
  }

  .Box {
    border-radius:5px;
    width: 30px;
    height: 30px;
    transition: all 1.1s cubic-bezier(.19,1,.22,1);
    background-color: transparent;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border:1px solid $davinci-mainOrange;
    &.square:after {
      width: 50%;
      height: 25%;
      content: '';
      position: absolute;
      border-left: 1.5px solid;
      border-bottom: 1.5px solid;
      border-color: $davinci-white;
      transform: rotate(-45deg) translate3d(0,0,0);
      transform-origin: center center;
      transition: all 1s cubic-bezier(.19,1,.22,1);
      left: 0;
      right: 0;
      top: 200%;
      bottom: 5%;
      margin: auto;
    }
  }
}

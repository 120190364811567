.TopBarExchangeRate{
  svg{
    width: 20px;
  }
  * {
    vertical-align: middle;
  }
  p{
    margin: 5px 0;
  }
}
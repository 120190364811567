@import 'src/assets/styles/colors';

.VisitsSideList{
  width: 15%;
  margin-left: 15px;
  height: 85%;
  overflow: scroll;

  .visit-card{
    min-height: 60px;
    padding: 5px;
    float: left;
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
    border-radius: 11px;
    font-size: 13.6px;
    background-color: rgba(61, 121, 255, 0.12);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.selected{
      background-color: $davinci-mainOrange;
    }
  }
}

@import "../../assets/styles/colors";
@import "../../assets/styles/measures";
@import "../../assets/styles/mediaqueries";


.LoadingAnimation.spinner {
  width: 70px;
  text-align: center;
  display: inline-block;

  > div {
    width: 12px;
    height: 12px;
    background-color: $davinci-mainOrange;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: bounce 1.4s infinite ease-in-out both;
    animation: bounce 1.4s infinite ease-in-out both;
  }

  &.white > div{
    background-color: white;
  }

  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}

@-webkit-keyframes bounce {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
}

.SingleCheckInForm{
  .form-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  .form-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

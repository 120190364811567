@import "../../assets/styles/colors";

.FullscreenDatePicker{

  .FullscreenPicker-container{

    .picker-btn{
      padding: 8px 15px;
      background: none;
      font-size: 1.3em;
      border: 1px solid rgba(255, 137, 2, 0.43);//davinci-mainOrange but with opacity
      margin: 4px;
      border-radius: 5px;

      &.selected{
        background-color: $davinci-mainOrange;
      }
    }

    .part-container{
      display: flex;
      justify-content: center;
      border-bottom: 2px solid $davinci-blue;
      padding: 20px 0;
      text-align: center;
    }

    .year-picker-container{

      .years-block{
        padding: 0 30px;
        max-width: 500px;
        border-left: 1px solid rgba(61, 121, 255, 0.25);
        border-right: 1px solid rgba(61, 121, 255, 0.25);
      }

      .change-decade-block{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 30px;
        button{
          background: none;
          color: white;
          border: none;
          font-size: 1.6em;
          font-weight: bold;
          outline: none;
          cursor: pointer;
          border-radius: 10px;
          padding: 8px 8px 8px 0;
          &:focus{
            border: 1px solid $davinci-mainOrange;
          }
        }
        .year-arrow{
          height: 30px;
          vertical-align: middle;
          position: relative;
          top: -2px;
          margin: 0 15px;
          path {
            fill: $davinci-blue;
          }
          &.next{
            transform: rotate(180deg);

          }
        }

      }

    }

    .month-picker-container{
      display: flex;
      justify-content: center;
      padding: 20px 0;
      .month-buttons-wrapper{
        max-width: 850px;
      }
      .picker-btn{
        min-width: 120px;
      }
    }

    .date-picker-container{
      border: none;

      .date-buttons-wrapper{
        max-width: 950px;

        .picker-btn{
          padding: 10px 11px;
          border-radius: 30px;
          min-width: 48px;
        }
      }
    }

  }
}

.FullscreenDatePickerInput{
  position: relative;
  border-radius: 8px;

  input{
    display: block;
    width: 100%;
    padding-left: 50px;
  }

  .calendar-icon{
    position: absolute;
    fill: white;
    height: 2em;
    margin: 5px 5px;
    cursor: pointer;
    top: 0;
    left: 0;
    path {
      fill: white;
    }
  }

  input:focus+.calendar-icon path{
    fill: $davinci-blue;
  }
  &.errored{
    border: 1px solid red;
    color: red;
  }
}

@import "../../assets/styles/colors";
@import "../../assets/styles/measures";
@import "../../assets/styles/mediaqueries";

.Alarms {
  width: 95%;
  height: 100vh;
  margin: 0 auto;

  .AlarmsHeader {
    margin-bottom: 25px;
    margin-left: 50px;
  }

  .AlarmsTableContainer {
    height: 80%;
    overflow: scroll;
    overflow-x: hidden;
    padding: 1rem;

    .AlarmsTable {
      width: 100%;
      border-collapse: separate;
      /*margin-left: 50px;*/
      border-spacing: 0 1rem;

      .AlarmsTableHeaders {
        color: $davinci-mutedWhite;
        font-size: $root-font-size * 1.2;
      }

      .scrollableDiv {
        height: 150px;
        overflow: scroll;
      }

      tbody {
        tr {
          height: 5rem;
          background-color: #ffffff33;
          text-transform: uppercase;
          text-align: center;
          font-family: Montserrat-semibold, sans-serif;
          font-size: $root-font-size;
        }
      }
    }
  }

  .AlarmsRow{
    padding-left: 50px;
    padding-right: 50px;
  }
}
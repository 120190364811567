@import "../../../assets/styles/colors";
@import "../../../assets/styles/measures";
@import "../../../assets/styles/mediaqueries";

.PaymentFormOverlay-container{
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.PaymentFormOverlay{
  width: 900px;
  min-height: 270px;
  margin: auto;
  z-index: 45;
  padding: $items-space;
  border-radius: $element-borderRadius;
  background:$davinci-grey-gradient;
  max-height: 90%;
  overflow-y: auto;

  &-overlay{
    background-color: rgba(0,0,0,.75);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 43;
  }

  &-closeCross{
    width: 30px;
    height: 30px;
    background-color: transparent;
    position: absolute;
    top: 10px;
    right:10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      width: 80%;
      height: 1px;
      background-color: $davinci-white;
      transform: rotate(45deg);
    }
    &::after{
      content: '';
      position: absolute;
      width: 80%;
      height: 1px;
      background-color: $davinci-white;
      transform: rotate(-45deg);
    }
  }
  &-icons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > svg{
      width: 30px;
      height: 30px;
    }

  }
  &-currency{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 80px;
    right: 20px;
    width: 150px;
    height: 100px;
    box-shadow: 0 5px 3px rgba(0,0,0,.2);
    padding: $items-space;
    background-color:$davinci-lightWhite;
    border-radius: $element-borderRadius;
  }
  &-flag{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color:$davinci-white;
    &:not(:last-child){
      margin-bottom: $items-space;
    }
  }
  &-order{
    width: 80%;
    height: 100%;
  }
  &-title{
    //height: 7%;
    margin-bottom: $items-space;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $davinci-blue;
    color: $davinci-white;
    padding: $items-space/2 $items-space;
    border-radius: $element-borderRadius;
    position: relative;
    & p{
      font-size: $root-font-size*1.1;
      font-family: Montserrat-bold,sans-serif;
    }
  }
  &-date{
    margin-left: $items-space;
  }
  &-content{
    height: 90%;
    box-shadow: 0 5px 3px rgba(0,0,0,.2);
    padding: $items-space;
    background-color:$davinci-lightWhite;
    border-radius: $element-borderRadius;
  }
  &-flights{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $items-space*2;
    &-rightArrow{
      border-radius: $items-space;
      width: 100px;
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $davinci-black;
      transform: rotate(90deg);
      & svg{
        width: 40px;
        height: 40px;
        & > *{
          fill: $davinci-white;
        }
      }
    }
    &-departure, &-arrival{
      border-radius: $items-space;
      width: 80px;
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $davinci-white;
      border:1px solid $davinci-mainOrange;
      box-shadow: $davinci-BoxShadow;
    }
    &-short{
      font-family: 'Montserrat-semibold',sans-serif;
      font-size: $root-font-size*1.2;
      margin-bottom: $items-space/2;
    }
    &-name{
      font-size: $root-font-size*.8;
    }
  }
  &-billBreakDown{
    margin-bottom: $items-space;
  }
  &-info {
    &-bill{
      font-family: 'Montserrat-semibold',sans-serif;
      font-size: $root-font-size*1.2;
    }
    &-companions {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4%;

      &-number{
        width: 25px;
        height: 25px;
        background-color: $davinci-mainOrange;
        border-radius:100px;
        text-align: center;
        line-height: 25px;
        margin-right: 5px;
        font-size: $root-font-size;
        font-family: 'Montserrat-semibold',sans-serif;
        // position: absolute;
        // transform: translate(-50%,-50%);
        // top: 50%;
        // left: 20px;

      }
      &-list{
        &-items{
          display: flex;
          justify-content: center;
          list-style: none;
          padding: 7px 6px 7px 13px;
          height: 45px;
          color: $davinci-white;
          border-radius: $element-borderRadius;
          background-color: $davinci-lightWhite;
          position: relative;
          &.concept{
            align-items: center;
            justify-content: space-between;
            padding: 10px 6px 10px 13px;
          }
          & svg{
            width: 20px;
            height: 20px;
            position: relative;
            top: 3px;
            & path{
              fill: $davinci-white;
            }
          }
          &:not(:last-child) {
            margin-bottom: $items-space/2;
          }
        }
      }
      &-names {
        width: 48%;
        //height: 100px;
      }
      &-bill {
        width: 48%;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $davinci-white;
      }

    }
    .total-amount{
      background-color: $davinci-lightWhite;
      color: $davinci-white;
      border-radius: $element-borderRadius;
      box-shadow: $davinci-BoxShadow;
      padding: 10px;
      text-align: center;
      font-weight: bold;
      font-size: 1.2em;
      margin-bottom: 20px;
    }

    .total-payed-container{
      text-align: center;
      p{
        padding: 18px 0 0 0;
      }
      .total-payed-item{
        display: inline-block;
        margin: 0 10px;
        border-radius: $element-borderRadius;
        background-color: $davinci-lightWhite;
        padding: 8px;
        min-width: 190px;
      }
    }

    &-cashRows{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: $items-space/2;
      & > *:not(:last-child){
        margin-right: $items-space/2;
      }
      p{
        display: inline-block;
        vertical-align: middle;
      }

      & svg{
        width: 25px;
        height: 25px;
        margin-right: $items-space/2;
        vertical-align: middle;
      }

      &-inputAmount{
        display: flex;
        align-items: center;
        border: 1px solid $davinci-mainOrange;
        border-radius: $element-borderRadius/1.2;
        position: relative;
        width: 150px;
        & p{
          position: absolute;
          transform: translate(-50%,-50%);
          right: -10px;
          top: 50%;
          color: $davinci-white;
          font-size: $root-font-size*.8;
        }
        & input{
          width: 100%;
          height: 100%;
          padding: 7px 16px 7px 26px;
          border-radius: $element-borderRadius;
          border: none;
          font-size: $root-font-size;
          color:$davinci-white;
          font-family: 'Montserrat-medium',sans-serif;
          background-color: transparent;
          letter-spacing: 1px;
          outline: none;
          &::placeholder{
            color: $davinci-mutedWhite;
            font-size: $root-font-size*1.2;
            font-family: 'Montserrat-medium',sans-serif;
          }

        }
        & svg{
          height: 60%;
          width:50px;
          position: absolute;
          transform:translate(-50%,-50%);
          top: 50%;
          left: 15px;
          & path{
            fill: $davinci-white;
          }
        }
      }

      &-convertedAmount{
        width: 148px;
        text-align: center;
      }
      &-btn{
        width: 80px;
      }
      &-select{
        width: 200px;
        text-align: center;
      }
    }
    &-item{
      &-charge{
        width: 100%;
        margin-bottom: $items-space/2;
      }
    }
  }
  &-payment{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: $items-space;
    &-method{
      width: 300px;
      display: flex;
      border-radius: $element-borderRadius;
      border: 1px solid $davinci-mainOrange;
      &-creditCard{
        width: 50%;
        border-right: 1px solid $davinci-mainOrange;
        & button{
          display: flex;
          align-items: center;
          background-color: transparent;
          border: none;
          width: 100%;
          height: 100%;
          color: $davinci-white;
          font-size: $root-font-size;
          font-family: 'Montserrat-semibold',sans-serif;
          padding: $items-space/2;
          outline: none;
          border-radius:$element-borderRadius 0 0 $element-borderRadius;
          transition: background-color .5s;
          & svg{
            width: 30px;
            height: 30px;
            margin-right: $items-space;
          }
          &:focus{
            background-color: $davinci-mainOrange;
          }
        }
      }
      &-cash{
        width: 50%;
        & button{
          display: flex;
          align-items: center;
          background-color: transparent;
          border: none;
          width: 100%;
          height: 100%;
          color: $davinci-white;
          font-size: $root-font-size;
          font-family: 'Montserrat-semibold',sans-serif;
          padding: $items-space/2;
          outline: none;
          transition: background-color .5s;
          border-radius:0 $element-borderRadius $element-borderRadius 0;
          & svg{
            width: 30px;
            height: 30px;
            margin-right: $items-space;
          }
          &:focus{
            background-color: $davinci-mainOrange;
          }
        }
      }
    }
  }

  .voucher-row{
    margin-bottom: 10px;
    .voucher-input{
      background: none;
      border: 1px solid $davinci-mainOrange;
      font-size: $root-font-size;
      color: white;
      padding: 8px 10px;
      border-radius: $element-borderRadius;
      width: 48%;
      margin-right: 4%;
      outline: none;
      font-family: 'Montserrat-medium', sans-serif;
    }
    .bank-select{
      display: inline-block;
      width: 48%;
      border-radius: $element-borderRadius;
    }
    .card-number-input{
      margin-top: 4%;
    }
  }

}
.companions-title{
  //height: 35%;
  //padding: 9px 0;
  border-radius: $element-borderRadius/1.5;
}

.FoodBarMenu{
}
.WarningModal{
    text-align:center;
    .productsList{
        width: 50%;
        margin: auto;
    }
    .Button{
        display: inline-block;
        margin: 50px;
        font-size: 1.7em
    }
}

@import "../../assets/styles/colors";
@import "../../assets/styles/measures";
@import "../../assets/styles/mediaqueries";


.GuestProfile{
  &-content{
    height: 90%;
    background:$davinci-black-gradient;
    width: 100%;
    border-radius: $element-borderRadius;
    padding: $items-space;
    position: relative;
    overflow-y: scroll;
    @include responsive(tablet){
      background-color: $davinci-grey-gradient;
      z-index: 20;
    }
    & *{
      z-index: 2;
    }

    &.non-grata{
      background: linear-gradient(126deg, #1e1e1c 4%, #ea0000 85%);
    }
    &.isVip{
      background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
      radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
    }
  }
  &-header{
    height: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.headerSize{
      height: 30%;
    }
  }
  &-img{
    background-size:contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 80%;
    height: 80%;
  }
  &-name{
    text-align: center;
    font-size: $root-font-size*1.2;
    color: $davinci-white;
    font-family: 'Montserrat-semibold',sans-serif;
  }
  &-birthday{
    font-size: $root-font-size/1.05;
    color: $davinci-white;
    font-family: 'Montserrat-semibold',sans-serif;
  }
  &-bottom{
    height: 86%;
  }

  .behaviour-reason{
    text-transform: uppercase;
    font-style: italic;
  }

  .active-lounge{
    text-align: center;
    margin-top: $items-space;
    span {
      background-color: $davinci-mainOrange;
      color: $davinci-black;
      font-weight: bold;
      display: inline-block;
      border-radius: 10px;
      padding: 4px 8px;
      font-size: 0.8em;
    }
  }

  &-personBehave,&-personStatus{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $davinci-lightWhite;
    margin-top: $items-space;
  }
  &-content.non-grata {
    overflow-y: scroll;
    .GuestProfile-personBehave {
      color: $davinci-mainOrange;
      //font-weight: bold;
      font-size: 1.4em;
    }
  }
  &-content.isVip {
    overflow-y: scroll;
    .GuestProfile-personStatus {
      color: $davinci-white;
      //font-weight: bold;
      font-size: 1.4em;
    }
  }
  &-Acces-last{
    padding: $items-space/5;
    color: $davinci-blue;
    border-bottom: 1px solid $davinci-blue;
    font-size: $root-font-size/1.05;
    margin-top: $items-space;
  }
  &-listContent{
    height: 70px;
    overflow-y: scroll;
    margin-top: $items-space/2;
    padding: 0 5px;
    color: $davinci-white;
    font-size: $root-font-size/1.2;
    display: flex;
    justify-content: center;
    align-items: center;

    &.selectable li{
      cursor: pointer;
    }

  }
  &-noInfo{
    font-size: $root-font-size*1.2;
    color: $davinci-mutedWhite;
  }
  &-list{
    width: 100%;
    height: 100%;
    &-item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include responsive(big-laptop){
        flex-direction: column;
      }
      & > p{
        @include responsive(big-laptop){
          margin-bottom: $items-space/2;
        }
      }
      &:not(:last-child){
        margin-bottom: $items-space/2;
        padding-bottom: $items-space/2.5;
        border-bottom: 1px solid $davinci-blue;
      }
    }
  }
  &-services{
    height:120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: $items-space;
    overflow-y: scroll;
    @include responsive(big-laptop){
      flex-direction: column;
      height:150px;
    }
      &-left{
        width: 45%;
        height: 100%;
        @include responsive(big-laptop){
         width: 100%;
         margin-bottom: $items-space;
          overflow-y: scroll;
        }
      }
      &-right{
        width: 100%;
        height: 100%;
        @include responsive(big-laptop){
          width: 100%;
          overflow-y: scroll;
        }
      }
    &-title{
      color: $davinci-blue;
      border-bottom: 1px solid $davinci-blue;
      margin-bottom: $items-space/1.8;
      padding-bottom: $items-space/3;
      & p{
        font-size: $root-font-size/1.2;
      }
    }
    &-card{

      color: $davinci-white;
    }
  }
  &-card{
    background-color: $davinci-lightBlue;
    border-radius: $element-borderRadius/1.5;
    padding: $items-space/4;
    &:not(:last-child){
      margin-bottom: $items-space/4;
    }
  }
  &-food{
    &-card{

      color: $davinci-white;
    }
  }
  &-comments{
    margin-top: $items-space;
    &.commentsSize{
      height: 15%;
    }
    &-title{
      color: $davinci-blue;
      border-bottom: 1px solid $davinci-blue;
      margin-bottom: $items-space/1.8;
      padding-bottom: $items-space/3;
      height: 15%;
    }
    &-chat{
      color: $davinci-white;
      padding-right: $items-space;
      overflow-y: scroll;
    }
  }
  &-chatBox{
    background-color: $davinci-black;
    margin-bottom: $items-space/1.8;
    padding: $items-space/3;
    border-radius:$element-borderRadius;
    position: relative;
    &:after{
      content: '';
      position: absolute;
      width: 25px;
      height: 20px;
      border-radius: 50%;
      box-shadow: 4px 3px 0 #060607;
      transform: rotateZ(105deg);
      bottom: 5px;
      right: -18px;

    }
  }
}
.BirthdayIcon{
  position: absolute;
  height: 20px;
  width: 20px;
  top: 90px;
  left: 10px;
& svg{
  height: 40px;
  width: 40px;
}
}
.isVipText{
  color: $davinci-black;
}
.isVipTitles{
  color: $davinci-white;
}
.isVipBorder{
  border-bottom-color: $davinci-white;
}


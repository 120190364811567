@import "../../../../../../assets/styles/colors";
@import "../../../../../../assets/styles/measures";

.CheckInBreakDown{
  width: 100%;
  background:$davinci-black-gradient;
  padding: $items-space/2;
  display: flex;
  flex-direction: column;
  color:$davinci-black;
  border-radius: $element-borderRadius;
  height: 41%;
  overflow-y: scroll;
  &-companionsController{
    display:grid;
    display: -ms-grid;
    grid-template-columns: 30% 40% 30%;
    -ms-grid-columns: 30% 40% 30%;
    margin-bottom: $items-space/2;
  }
  &-companions{
    display:flex;
    align-items: center;
    color: $davinci-white;
    & p{
      font-size: $root-font-size *.85
    }
  }
  &-timeWrapper{
    display: flex;
    flex-direction: column;
    color: $davinci-white;
  }
  &-timeCheckIn,&-timeCheckOut{
    display: grid;
    display: -ms-grid;
    grid-template-columns: 30% 40% 30%;
    -ms-grid-columns: 30% 40% 30%;
    margin-bottom: $items-space/2;
    min-height: 38px;
  }
  &-title{
      font-size: $root-font-size*.9;
    align-self: center;
    &-text{
      font-family: Montserrat-semibold,sans-serif;
      justify-self: center;
      align-self: center;
    }
  }
  &-timeExpiration{
    display: grid;
    display: -ms-grid;
    grid-template-columns: 30% 40% 30%;
    -ms-grid-columns:  30% 40% 30%;
  }
  .folio{
    color: lightgray;
  }
}

@import "../../assets/styles/measures";
@import "../../assets/styles/colors";

.CashRegister{

  .content{
    margin: 10px $items-space*4 20px;
  }
  .header{
    svg {
      font-size: 1.6em;
    }
    border-radius: $element-borderRadius;
    background-color: $davinci-grey;
    padding: 20px;
  }

  .side-block{
    border-radius: $element-borderRadius;
    background-color: $davinci-grey;
    margin: 20px 0;
    padding: 20px;
    min-height: 315px;
    h3{
      margin: 0 0 15px 0;
      text-align: center;
    }

    &.close-block{
      text-align: center;
      .shift-desc{
        margin-top: 40px;
        font-weight: bold;
      }
      .btn-container{
        font-size: 1.2em;
        padding-top: 60px;
        .Button{
          display: inline-block;
        }
      }
    }
  }
}

.ServerSelection{
  min-height: 100vh;
  padding: 30px;
  .servers-container{
    display: flex;
    @media screen and (max-width: 1000px){
      flex-direction: column;
    }
    .server-block{
      margin: 15px;
      border: 1px solid white;
      padding: 15px;
      border-radius: 10px;
      width: 300px;
      max-width: calc(100vw - 60px);
      .server-name{
        font-size: 1.2em;
        font-family: Montserrat-semibold, sans-serif;
        margin-bottom: 15px;
      }
      .server-address{
        margin: 18px 0;
      }
    }
  }
}

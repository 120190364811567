@import "../../assets/styles/colors";
@import "../../assets/styles/measures";
@import "../../assets/styles/mediaqueries";


.FrontDesk{
  background-color: $davinci-black;
  width:100vw;
  height: 100vh;
  position: relative;

  &-overlay{
    background-color: rgba(0,0,0,.9);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 43;
  }
  &-content{
    height: calc(100vh - #{$topBar-height});
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    margin: 0 auto;
    padding-bottom: $items-space*3;
    position: relative;
    z-index: 5;

    @include responsive(big-laptop){
      width: 95%;
    }
    @include responsive(tablet){
     justify-content: start;
    }
  }
  &-right{
    width: 22%;
    height: 100%;
    @include responsive(tablet){
      width: 100%;
      height: 25%;
      display: flex;
      justify-content: space-between;
    }
  }
  &-left{
    width: 19%;
    height: 100%;
    @include responsive(tablet){
      width: 25%;
      height: 70%;
      margin-bottom: $items-space;
      margin-right: $items-space;
    }
  }

  .start-button-container{
    position: absolute;
    left: calc(19% + 44px);
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.7);
    max-width: 54%;
    width: 54%;

    & > .Button {
      font-size: 28px;
    }
  }
}

@import "../../assets/styles/colors";
@import "../../assets/styles/measures";
@import "../../assets/styles/mediaqueries";

.FrontDeskGuest{
  background-color: $davinci-black;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  @include responsive(smallAndShortDevice){
      padding: 0 40px;
  }
  .LoadingAnimation{
    display: inline-block;
  }
  &-userInfo{
    position: absolute;
    width: 90px;
    height: 40px;
    background-color: $davinci-mainOrange;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 19;
    border-radius: 0 100px 100px 0;
    left: -30px;
    top: 8%;
    transition: left .5s;
    &.userInfoOpen{
      left:290px;
    }
    & svg{
      width: 30px;
      height: 30px;
      & > *{
        fill: $davinci-white;
      }
    }
  }
  &-moreInfo{
    position: absolute;
    width: 90px;
    height: 40px;
    background-color: $davinci-mainOrange;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 90;
    border-radius: 100px 0 0 100px;
    right: -30px;
    top: 8%;
    & svg{
      width: 25px;
      height: 25px;
      margin-left: $items-space/4;
      & > *{
        fill: $davinci-white;
      }
    }
  }
  &-titleBar{
    height: 7%;
    margin-bottom: $items-space
  }
  &-content{
    height: calc(100vh - #{$topBar-height});
    width: 88%;
    margin: 0 auto;
    padding-bottom: $items-space*3;
    @include responsive(big-laptop){
      width: 95%;
    }
    @include responsive(smallAndShortDevice){
      padding-bottom: 25px;
    }
  }
  &-title{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $davinci-blue;
    color: $davinci-white;
    padding: $items-space/2 $items-space;
    border-radius: $element-borderRadius;
    margin-bottom: $items-space;
    position: relative;
    width: 100%;
    .back-arrow-btn{
      cursor: pointer;
      background: none;
      border: none;
      position: absolute;
      top: 2px;
      left: 8px;
      svg{
        width: 50px;
        height: 50px;
        path{
          fill: white;
        }
      }
    }
    &.widthAnimation{
      animation: FrontDeskGuestWidthAnimation 0.5s;
    }
    & p{
      font-size: $root-font-size*1.4;
      font-family: Montserrat-bold,sans-serif;
    }
  }
  &-sections{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    height: 90%;
  }
  &-right{
    width: 80%;
    height: 100%;
    @include responsive(tablet){
      width: 100%;
    }
  }
  &-left{
    width: 19%;
    height: 100%;
    position: relative;
    @include responsive(tablet){
      width: 100%;
    }
    &-tabletVersion{
      position: absolute;
      left: -400px;
      background-color: $davinci-black;
      height: 100%;
      transition: left .5s;
      width: 300px;
      &.tabletVersionOpen{
        left: 0;
      }
    }
  }
  &-date{
    margin-left: $items-space*1.5;
  }
  &-editIcon{
    position: absolute;
    top: 5px;
    right: 10px;
    width: 20px;
    height: 20px;
    z-index: 21;
    cursor: pointer;
    & svg{
      width: 20px;
      height: 20px;
      cursor: pointer;
      & path{
        fill: $davinci-blue;
      }
    }
  }
}
.confettiIcon{
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 500px;
  height: 500px;
  & svg{
    width: 500px;
    height: 500px;
  }
}
.confettiIcon2{
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 100;
  width: 500px;
  height: 500px;
  & svg{
    transform: rotate(-90deg) !important;
    width: 500px;
    height: 500px;
  }
}
@keyframes FrontDeskGuestWidthAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@import "../../../../../assets/styles/colors";
@import "../../../../../assets/styles/measures";


.GuestVisitHistory{
  background:$davinci-black-gradient;
  color:$davinci-black;
  border-radius: $element-borderRadius;
  padding: $items-space/2;
  overflow-y: scroll;

  .selectBox{
    .services{
      margin-bottom: $items-space;
    }
  }
  .title{
    font-family: Montserrat-semibold,sans-serif;
    font-size: $root-font-size*1.4;
    color: $davinci-white;
    text-align: center;
    margin-bottom: $items-space;
  }
  .content{

  }
  .right{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .left{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .subTitle{
    padding: $items-space/2;
    color: $davinci-blue;
    border-bottom: 1px solid $davinci-blue;
    text-align: center;
    width: 80%;
    margin-bottom: $items-space;
  }
  .cards{
    width: 100%;
    //overflow-y: scroll;
  }
  .list{
    width: 100%;
    list-style: none;
    font-size: $root-font-size*1.2;
    color: $davinci-white;
    padding: 0 10px;


    .item{
      background-color:$davinci-lightBlue;
      border-radius: $element-borderRadius;
      padding: $items-space/2;
      position: relative;

      .main-description{
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .cancel-item{
        cursor: pointer;
        float: right;
        background: none;
        border: none;
        color: white;
        font-size: 22px;
      }

      .single-modifier {
          text-align: center;
          border: 1px solid white;
          border-radius: $element-borderRadius;
          margin-top: 5px;
      }
          &:not(:last-child){
        margin-bottom: $items-space;
      }
      &.canceled{
        background-color: $davinci-danger;
        text-decoration: line-through;
      }
    }
  }

  .dishes-block{
    margin-top: 20px;
  }
  .nothing-here{
    text-align: center;
    opacity: 0.4;
    font-size: 1.7rem;
    margin-top: 20px;
    color: white;
  }
  .dollar-con.red{
    color: $davinci-danger;
  }

}

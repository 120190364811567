@import "../../../../assets/styles/colors";
@import "../../../../assets/styles/measures";
@import "../../../../assets/styles/mediaqueries";

.VisitInfo{
  height: 100%;
  display: flex;
  position: relative;
  top: 0;

  .history-and-additions{
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-bottom: $items-space;
  }
  .ServiceAndExtraChargeAdder{
    margin-bottom: $items-space;
  }
  .GuestVisitHistory{
    flex-grow: 1;
  }
  &-servicesAndCheckIn{
    display: flex;
    justify-content: space-between;
    height: calc(78.5% - #{$items-space});
    margin-bottom: $items-space;
  }
  &-checkInInfo{
    display: flex;
    flex-direction: column;
    width: 48%;
  }

  &-right{
    width: 30%;
    position: absolute;
    right: 0;
    height: 100%;
    padding-left: $items-space;
    @include responsive(tablet){
      width: 100%;
    }
    &-tabletMode{
      position: absolute;
      right: -300px;
      background-color: $davinci-black;
      height: 100%;
      width: 100%;
      transition: right .5s;
      &.tabletModeOpen{
        right: 0;
      }
    }
  }
  &-left{
    width: 70%;
    padding: 0 $items-space;
    position: relative;
    @include responsive(tablet){
      width: 100%;
    }
  }
  &-services{
    border-radius: $element-borderRadius;
    &-btn{
      margin-top: $items-space;
    }
  }
  &-visits{
    border-radius: $element-borderRadius;
    background-color: $davinci-mainOrange;
    padding: $items-space;
    &-title{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $root-font-size*1.2;
      font-family: Montserrat-semibold,sans-serif;
      background-color: $davinci-lightWhite;
      border-radius: $element-borderRadius;
      padding:$items-space/2;
    }
    &-list{
      width: 100%;
      height: 100%;
      list-style: none;
        &-item{
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
    }
  }
  &-selectBox{
    border-radius: $element-borderRadius;
    height: calc((100% / 4) - #{$items-space});
    display: flex;
    justify-content: space-between;
    margin-bottom: $items-space;
    &-services{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 48%;
    }
  }
  &-btnSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: calc(100% / 6);
  }
  &-btn:not(:last-child){
    margin-bottom: $items-space/2;
  }
  &-checkout{
    width: 100%;
    height: 9%;
  }
  .transfer-btn{
    position: relative;
  }
}

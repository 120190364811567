
$davinci-black:#060607;
$davinci-mutedBlack:rgba(39, 33, 39, 0.5);
$davinci-grey:#454445;
$davinci-success:#4BB543;
$davinci-lightSuccess: rgba(0, 181, 53, 0.9);
$davinci-danger: rgb(243, 32, 19);
$davinci-lightDanger: rgba(243, 32, 19, 0.6);
$davinci-mainOrange: #FF8902;
$davinci-secondOrange: rgba(255, 137, 2, 0.4);
$davinci-lightOrange:rgba(255,137,2,.2);
$davinci-mutedOrange:rgba(255,137,2,.16);
$davinci-blue:#3D79ff;
$davinci-lightBlue:rgba(61,121,255,.12);
$davinci-white:#fff;
$davinci-lightWhite:rgba(255,255,255,.2);
$davinci-mutedWhite:rgba(255,255,255,.5);
$davinci-grey-gradient:#2E2E2F;
$davinci-gold:#efb810;
$davinci-mutedBrown:#B57700;

$davinci-black-gradient:transparent linear-gradient(180deg, rgba(250, 245, 246, 0.17) 0%, rgba(252, 250, 250, 0.17) 48%, rgba(253, 253, 253, 0.17) 100%) 0% 0% no-repeat padding-box;






.MrzScanner {

  margin: 20px 0;

  &-accept-btn-container {
    text-align: center;
    margin: 25px 0;
    .repeat-btn {
      font-size: 22.1px;
    }
    button {
      display: inline-block;
      margin: 0 15px;
    }
  }

  &-videoContainer{
    position: relative;
    .loading-overlay{
      position: absolute;
      background-color: rgba(0,0,0,0.4);
      top: 0; left: 0;
      width: 100%; height: 100%;
      overflow: hidden;
      z-index: 100;
      .loading-anim{
        width: 50%;
        margin: auto;
      }
    }
  }

  .croppie-container .cr-viewport {
    box-shadow: 0 0 2000px 2000px rgba(173, 173, 173, 0.5);
  }

  .croppie-container .cr-boundary {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  .croppie-container .cr-image {
    z-index: -1;
    top: 0;
    left: 0;
    transform-origin: 0 0;
    max-height: none;
    max-width: none;
  }

  .croppie-container .cr-resizer, .croppie-container .cr-viewport {
    position: absolute;
    border: 2px solid #fff;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
  }

  .croppie-container .cr-overlay {
    z-index: 1;
    position: absolute;
    cursor: move;
    touch-action: none;
  }
}

@import "../../../../assets/styles/measures";
@import "../../../../assets/styles/colors";

.NewFlight {
    border-radius: $element-borderRadius;
    border: 1px solid $davinci-grey;
    padding: 1em 0.5em;
    .discard-btn{
        border-radius: $element-borderRadius;
        border: 1px solid $davinci-mainOrange;
        background: transparent;
        color: white;
        padding: 1em;
        float: right;
    }
}

@import "../../../assets/styles/colors";
@import "../../../assets/styles/measures";
@import "../../../assets/styles/mediaqueries";


.TopBarWaiterCommand{
  height: $topBar-height;
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  &-topBarGuestTableTabletVersion{
    position: absolute;
    top: 100px;
    left: 0;
    height: 30px;
    z-index: 50;
    background-color:black;
    border-radius: $element-borderRadius;
  }
  &-tabletVersion{
    width: 35px;
    height: 35px;
    margin-right: $items-space*2;
    cursor: pointer;
    &-icon{
      width: 35px;
      height: 35px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  &-title{
    font-size: $root-font-size*2.5;
    font-family: 'Montserrat-semibold',sans-serif;
    color: $davinci-white;
  }
  &-time{
    margin-right: $items-space*5;
    display: flex;
    align-items: center;
  }
  &-profile{
    display: flex;
  }
  &-bigTitle{
    font-size: $root-font-size*2;
    margin-left: $items-space*2.5;
  }
}

@import "../../../../assets/styles/colors";
@import "../../../../assets/styles/measures";
@import "../../../../assets/styles/mediaqueries";

.TopBar{
  &-profile{
    color:$davinci-white;
    display: flex;
    position: relative;
    &-wrapper{
      margin-right: $items-space;
    }
    &-name{
      font-size: $root-font-size*1.2;
      font-family: Montserrat-bold,sans-serif;
      max-width: 400px;
      text-align: right;
      @include responsive(big-laptop){
        font-size: $root-font-size;
      }
    }
    .session-bar{
      position: absolute;
      width: 100%;
      right: 0;
      bottom: -2px;
      height: 1px;
      background-color: white;
    }
    &-session{
      position: relative;
      text-align: right;
      font-size: $root-font-size*1.2;
      @include responsive(regular-laptop){
        font-size: $root-font-size*1.1;
      }
      @include responsive(big-laptop){
        font-size: $root-font-size;
      }
    }
    &-img{
      width: $profile-img-size;
      height: $profile-img-size;
      margin-right: $items-space;
      cursor: pointer;
    }
    &-bgImg{
      width: 100%;
      height: 100%;
      border-radius: 100px;
      background-color: #626262;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
  .DotMenu{
    height:$burgerBtn-width;
    cursor: pointer;
    border: none;
    background-color: transparent;
    margin-top: 20px;
    &-dot{
      background-color: $davinci-mainOrange;
      width: 8px;
      height: 8px;
      border-radius: 100px;
      position: relative;
      transform: translate(-50%,-50%);
      left: 50%;
      top:60%;
      &::after{
        content: '';
        position: absolute;
        background-color: $davinci-mainOrange;
        width: 8px;
        height: 8px;
        border-radius: 100px;
        top: 11px;
        left: 0;
      }
      &::before{
        content: '';
        position: absolute;
        background-color: $davinci-mainOrange;
        width: 8px;
        height: 8px;
        border-radius: 100px;
        bottom: 11px;
        left: 0;
      }
    }
  }
}
.flightTopBar{
  display: flex;
  justify-content: space-between;

  .TopBar-profile-img{
    width: 45px;
    height: 45px;
  }
  & p{
    font-size: $root-font-size*.9;
  }
}

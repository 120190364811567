@import '../../../../../../assets/styles/colors';
@import '../../../../../../assets/styles/measures';


.CheckInFormModal{

  text-align: center;

  &.charging{
    opacity: 0.2;
  }

  .SingleCheckInForm{
    text-align: left;
  }

  .form-container{
    background-color: $davinci-lightWhite;
    border-radius: $element-borderRadius;
    padding: 20px;
    margin-top: 40px;
  }

  .icons-container {
    border-radius: $element-borderRadius;
    border: 1px solid $davinci-mainOrange;
    display: block;
    width: 390px;
    padding: 30px;
    background: $davinci-grey;
    margin: 15px auto 30px;

    .center-arrow {
      height: 90px;
      width: 90px;
      margin: 0 50px;
      vertical-align: middle;

      path {
        fill: white;
      }
    }
    .icon {
      vertical-align: middle;
      font-size: 85px;
      margin: 0 30px;
    }
  }

  .Button{
    display: inline-block;
    font-size: 1.7em;
    margin: 50px;
  }
}

@import "../../assets/styles/colors";
@import "../../assets/styles/measures";
@import "../../assets/styles/mediaqueries";

.TopBar{
  height: $topBar-height;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding:0 $items-space;
  position: relative;
  z-index: 10;
  &-subMenu{
    display: flex;
    justify-content: space-evenly;
    top:$topBar-height - $items-space*2;
    left: 0;
    right: 0;
    padding:$items-space;
    margin-bottom: $items-space;
    position: absolute;
    background-color: $davinci-black;
    z-index: 50;
  }
  &-plusIcon{
    background-color: $davinci-mainOrange;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    & svg{
      width: 20px;
      height: 20px;
    }
  }
  &-ocupacion{
    display: flex;
    align-items: center;
    color: $davinci-white;
    &-text{
      font-size: $root-font-size*1.4;
      margin-right: $items-space;
      @include responsive(regular-laptop){
        font-size: $root-font-size*1.1;
      }
      @include responsive(big-laptop){
        font-size: $root-font-size;
      }
    }
  }
  &-progress{
    border-radius: 100px;
    border-color: $davinci-mainOrange;
    background-color: transparent;
    width: $loadingBar-width;
    height:$loadingBar-height;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
      &::-moz-progress-bar{
        background-color: $davinci-mainOrange;
        border-radius: 100px;
      }
      &::-webkit-progress-bar{
        border-radius: 100px;
        border: 1px solid $davinci-mainOrange;
        background-color: transparent;
      }
      &::-webkit-progress-value{
        background-color: $davinci-mainOrange;
        border-radius: 100px;
      }

    &-score{
      font-size: $root-font-size*1.4;
      color: $davinci-mainOrange;
      margin-right: $items-space;
      @include responsive(regular-laptop){
        font-size: $root-font-size*1.1;
      }
      @include responsive(big-laptop){
        font-size: $root-font-size;
      }
    }
  }
  &-disponibilidad{
    color:$davinci-white;
    font-size: $root-font-size*1.4;
    display: flex;
    align-items: center;
    position: relative;
    @include responsive(regular-laptop){
      font-size: $root-font-size*1.1;
    }
    @include responsive(big-laptop){
      font-size: $root-font-size;
    }
    & p{
      margin-right: $items-space;
    }
    &-drop{
      border:2px solid $davinci-mainOrange;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius:$drop-borderRadius;
      cursor: pointer;
      background-color: transparent;
      & svg {
        & path{
          fill: $davinci-mainOrange;
        }
      }
    }
  }
}
.burgerBtn{
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: $davinci-white;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: $davinci-white;
    top: 8px;
    left: 0;
  }
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: $davinci-white;
    bottom: 8px;
    left: 0;
  }
}
.DotMenu{
  height:$burgerBtn-width;
  cursor: pointer;
  border: none;
  background-color: transparent;
  &-dot{
    background-color: $davinci-mainOrange;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    position: relative;
    transform: translate(-50%,-50%);
    left: 50%;
    top:60%;
    &::after{
      content: '';
      position: absolute;
      background-color: $davinci-mainOrange;
      width: 8px;
      height: 8px;
      border-radius: 100px;
      top: 11px;
      left: 0;
    }
    &::before{
      content: '';
      position: absolute;
      background-color: $davinci-mainOrange;
      width: 8px;
      height: 8px;
      border-radius: 100px;
      bottom: 11px;
      left: 0;
    }
  }
}


@import "../../../../../assets/styles/colors";
@import "../../../../../assets/styles/measures";
@import "../../../../../assets/styles/mediaqueries";

.VisitCard {
  width: calc((100% / 3) - #{$items-space / 1.5});
  background-color: $davinci-lightWhite;
  border-radius: $element-borderRadius;
  color: $davinci-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: $root-font-size*0.9;
  text-align: center;
  padding: $items-space/3;
  margin-right: $items-space;
  margin-bottom: $items-space;
  cursor: pointer;
  animation-duration: .2s;
  @include responsive(tablet){
    width: 100%;
    margin-right: 0;
  }

  &:nth-child(3n) {
    margin-right: 0;
  }

  hr{
    color: $davinci-lightOrange;
    height: 1px;
    margin-bottom: $items-space/4;
    margin-top: $items-space/5;
  }

  &-info {
    height: 40%;
    display: flex;
    flex-direction: column;
    margin-bottom: $items-space*.5;

    &-titleBar {
      display: grid;
      display: -ms-grid;
      grid-template-columns: 20% 3fr 1fr;
      -ms-grid-columns: 20% 3fr 1fr;
      border-bottom: 1px solid $davinci-mainOrange;
      margin-bottom: $items-space/3;
      padding-bottom: $items-space/5;
      font-size: $root-font-size*0.8;
      color: $davinci-mutedWhite;
    }

    &-content {
      display: grid;
      display: -ms-grid;
      grid-template-columns: 20% 3fr 1fr;
      -ms-grid-columns:20% 3fr 1fr;
    }
  }

  &-details {
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: $items-space/2;
  }

  &-clientName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .waiter {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    img {
      width: 12px;
      margin-right: $items-space;
    }
  }

  &-detail-number{
    background-color: $davinci-blue;
    display: inline-block;
    padding: 8px;
    img{
      width: 15px;
    }
  }
  .ProgressBar-loadingTimer{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  &.has-warning{
    background-color: $davinci-lightDanger;
  }
  &.fullTime{
    background-color: $davinci-lightSuccess;
  }
  &.mediumTime{
    background-color: $davinci-lightOrange;
  }
  .single-number{
    font-size: $root-font-size*1.5;
  }
}
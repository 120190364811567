@import "../../../assets/styles/colors";
@import "../../../assets/styles/measures";
@import "../../../assets/styles/mediaqueries";

.AttendingVisits{
  width:100vw;
  height: 100vh;

  &.map .TopBarWaiterCommand{
    height: 100px;
  }
  &-content{
    height: calc(100vh - #{$topBar-height});
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-bottom:$items-space;
  }
  &-left{
    height: 100%;
    width: 15%;
    margin-right: $items-space;
    @include responsive(tablet){
      width: 30%;
    }
  }
  &-middle{
    height: 100%;
    width: 85%;
    @include responsive(tablet){
      width: 65%;
    }
  }

  &-name,&-guest{
    width: 100%;
    height: 55px;
    margin-bottom: $items-space/2;
    background-color: $davinci-blue;
    font-size: $root-font-size*1.5;
    font-family: Montserrat-semibold,sans-serif;
    border-radius: $element-borderRadius;
    color: $davinci-white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-guest{
    position: relative;
    .back-arrow-btn{
      cursor: pointer;
      background: none;
      border: none;
      position: absolute;
      top: 5px;
      left: 8px;
      svg{
        width: 50px;
        height: 50px;
        path{
          fill: white;
        }
      }
    }
  }
  &-cards,&-clients{
    padding-top: ($items-space/2)+2;
    height: 90%;
    overflow-y: scroll;
  }

  &-clients{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
  }

  .no-guests-msg{
    width: 100%;
    color: $davinci-mutedWhite;
    margin-top: 50px;
    text-align: center;
  }

  .all-visits, .unassigned-visits{
    width: calc(50% - 4px);
    float: left;
  }
  .all-visits{
    margin-left: 4px;
  }
  .unassigned-visits{
    margin-right: 4px;
  }
}





@import "../../../../../assets/styles/colors";
@import "../../../../../assets/styles/measures";

.SeatsList {
  width: 100%;
  background-color: $davinci-mutedWhite;
  padding: $items-space;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #060607;
  border-radius: 11px;
  height: calc((100% / 8) - 20px);
  margin-bottom: 20px;
  position: relative;

  &-text {
    font-family: 'Montserrat-semibold', sans-serif;
    font-size: $root-font-size*0.9;
    color: $davinci-white;
    letter-spacing: 1px;
    font-weight: lighter;
  }
  
  &-title{
    font-family: 'Montserrat-semibold', sans-serif;
    font-size: $root-font-size*0.9;
    color: $davinci-white;
    letter-spacing: 1px;
  }
}
@import "../../../../../assets/styles/measures";
@import "../../../../../assets/styles/colors";

.RecursiveMenu{

  position: relative;
  .menu-item{
    border-radius: $element-borderRadius;
    padding: 8px;
    border: 1px solid $davinci-mainOrange;
    background-color: $davinci-mutedOrange;
    margin: 8px 0;
    cursor: pointer;
    &.selected{
      background-color: $davinci-mainOrange;
      font-size: 1.1em;
    }
    &:active{
      transform: scale(.95);
    }
  }
  .adding-options-front{
    backface-visibility: hidden;
    animation: RecursiveMenuBack 400ms forwards;
  }
  .adding-options-back{
    backface-visibility: hidden;
    animation: RecursiveMenuFront 350ms forwards;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

@keyframes RecursiveMenuFront {
  0%   {transform: rotateY(180deg)}
  100% {transform: rotateY(0deg)}
}
@keyframes RecursiveMenuBack {
  0%   {transform: rotateY(0deg)}
  100% {transform: rotateY(180deg)}
}
@import "../../assets/styles/colors";
@import "../../assets/styles/measures";

.FullScreenEmployeePicker{
  h1{
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    background-color: #3D79ff;
    border-radius: 11px;
    padding-top: 10px;
    padding-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: $items-space;
    padding-right: $items-space;
    margin-top: 0;
  }
  .WaiterCard{
    width: 15%;
    margin-right: $items-space;
    min-height: 80px;
    float: left;
    margin-top: 0;
    margin-bottom: $items-space;
  }
  .WaiterCard:not(:last-child) {
    margin-bottom: $items-space;
  }

  .FullscreenPicker-container{
    padding-top: $items-space*1.5;
    padding-bottom: $items-space*1.15;
  }

}

@import "../../../../../assets/styles/colors";
@import "../../../../../assets/styles/measures";

.CancelCheckInModal{
  &-comments{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &-textArea{
    height: 200px;
  }
}
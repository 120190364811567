@import "../../../../assets/styles/colors";
@import "../../../../assets/styles/measures";
@import "../../../../assets/styles/mediaqueries";

.TopBarGuestTable{
  width: 620px;
  border-radius: $element-borderRadius;
  border: 2px solid $davinci-lightWhite;
  display: flex;
  padding: $items-space/5;
  margin-right: $items-space;
  @include responsive(tablet){
    width: 100%;
    height: 100%;
    border:none;
  }
  &-guest{
    display: flex;
  }
  &-name{
    display: flex;
    padding:0 $items-space;
    align-items: center;
    width: 100%;
    cursor: pointer;
    & span{
      font-size: $root-font-size*.8;
      color: $davinci-mainOrange;
      font-family: 'Montserrat-semibold',sans-serif;
      text-align: center;
      margin-right: $items-space;
    }
    & p{
      color: $davinci-white;
      font-size: $root-font-size*1.4;
      font-family: 'Montserrat-semibold',sans-serif;
      @include responsive(tablet){
        font-size: $root-font-size;
      }
    }
    & svg{
      width: 30px;
      height: 30px;
      margin-right: $items-space;
      @include responsive(tablet){
        width: 25px;
        height: 25px;
      }
      & > *{
        fill: white;
      }
    }
  }
  &-table{
    width: 30%;
    color: $davinci-mainOrange;
    font-size: $root-font-size*1.5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 2px solid $davinci-lightWhite;
    @include responsive(tablet){
      font-size: $root-font-size*1.2;
    }
  }
}

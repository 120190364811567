$root-font-size:16px;

$topBar-height:150px;

$profile-img-size:66px;

$items-space:20px;

$frontDesk-content-padding:100px;

$boxBar-height: 50px;

$burgerBtn-height:46px;
$burgerBtn-width:32px;

$loadingBar-width:120px;
$loadingBar-height:14px;

$element-borderRadius:11px;
$drop-borderRadius:4px;


$davinci-BoxShadow: 0 4px 5px rgba(0,0,0,.2);
$davinci-insetBoxShadow:inset 0 53px 37px -21px rgba(0,0,0,0.75);

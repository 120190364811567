@import "../../../../../assets/styles/colors";
@import "../../../../../assets/styles/measures";
@import "../../../../../assets/styles/mediaqueries";

.SpecialProductModal{
  &-input{
    margin-bottom: $items-space;
  }
  &-textArea{
    height: 200px;
    padding: 10px 70px 0 10px;
    margin-bottom: 0;
    width: 100%;
  }
  &-descriptionArea{
    position: relative;
    margin-bottom: $items-space;
  }
  &-keyBoardIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 40px;
    height: 40px;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 25px;
    background-color: transparent;
    border: none;
    right: 0;
    & svg{
      width: 40px;
      height: 40px;
      & > *{
        fill: $davinci-mainOrange;
      }
    }
  }
  &-counter{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    margin: 0 auto;
  }
  &-warning{
    margin-bottom: $items-space;
  }
}
@import "../../../../assets/styles/measures";
@import "../../../../assets/styles/colors";

.CashRegisterMoneyList.amounts-list {
    list-style: none;

    li {
      padding: 4px 20px 4px 4px;
      margin: 8px 0;
      font-size: 1.2em;
      border-radius: $element-borderRadius;
      background-color: $davinci-lightBlue;
      text-align: left;
    }

    .payment-icon {
      font-size: 1.4em;
      width: 44px;
      vertical-align: middle;
      float: left;
      padding-left: 5px;
    }

    .flag {
      width: 28px;
      vertical-align: middle;
      display: inline-block;
      margin: 0 15px;
      float: left;
    }
    .money-type{
      float: right;
    }

   &.smaller{
     li{
       font-size: 1em;
     }
     .payment-icon{
       font-size: 1.4em;
     }
     .flag{
       width: 22px;
     }
   }
  }
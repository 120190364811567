@import "../../assets/styles/colors";
@import "../../assets/styles/measures";
@import "../../assets/styles/mediaqueries";

.Flights{
  width: 100vw;
  height: 100vh;
  position: relative;
  padding: 20px 60px;

  @media screen and (max-width: 720px){
    margin-bottom: 100px;
  }

  .flightTopBar {
    .filterButton {
      position: relative;
      outline: none;
      background-color: transparent;
      border-radius: 100px;
      border:1px solid $davinci-mainOrange;
      color: $davinci-white;
      padding: 15px;
      cursor: pointer;
      font-size: 12px;
      font-family: Montserrat-semibold,sans-serif;
      text-align: center;
    }
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: $root-font-size*1.2;
    font-family: Montserrat-semibold, sans-serif;
    border: 1px solid $davinci-mainOrange;
    border-radius: $element-borderRadius;
    padding: $items-space/2;
    color: $davinci-mainOrange;
    width: 100%;
    position: relative;
    margin-top: 20px;

    &-input {
      height: 100%;
      width: 100%;
      background-color: transparent;
      font-size: $root-font-size*1.2;
      font-family: Montserrat-medium, sans-serif;
      border: none;
      outline: none;
      color: $davinci-mainOrange;
      cursor: pointer;
      padding-right: $items-space*2;

      &::placeholder {
        color: $davinci-mainOrange;
        font-family: Montserrat-semibold, sans-serif;
      }

      &:focus::placeholder {
        color: $davinci-lightWhite;
      }
    }
  }
  &-searchIcon{
    position: absolute;
    right: 50px;
    pointer-events: none;
    & svg{
      width: 20px;
      height: 20px;
      path{
        fill: $davinci-mainOrange;
      }
    }
  }
  &-keyBoardIcon{
    position: absolute;
    right: 8px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    & svg{
      width: 25px;
      height: 25px;
      path{
        fill: $davinci-mainOrange;
      }
    }
  }
  &-burguerMenu{
    position: absolute;
    top: 20px;
    left: 20px;
  }
  &-cardsWrapper{
    height: calc(100vh - 315px);
    overflow-y: scroll;
    @include responsive(tablet){
      font-size: 12px;
    }
  }
  &-title{
    border-radius: $element-borderRadius;
    box-shadow: $davinci-BoxShadow;
    padding: $items-space/2;
    display: grid;
    display: -ms-grid;
    grid-template-columns:5% 15% 10% 15% 15% 10% 20% 10%;
    -ms-grid-columns: 5% 15% 10% 15% 15% 10% 20% 10%;
    color: $davinci-mutedWhite;
    font-size: $root-font-size;
    margin-bottom: $items-space/2;
    text-align: center;
    @include responsive(tablet){
      font-size: 12px;
    }
  }
  &-column{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-hour{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-wrap: wrap;
    &-item{
      position: relative;
      background-color: transparent;
      border-radius: 100px;
      border:1px solid $davinci-mainOrange;
      width: calc((100% / 24));
      height: calc((100% / 24));
      color: $davinci-white;
      padding: $items-space;
      cursor: pointer;
      &.active{
        background-color: $davinci-mainOrange;
        color: $davinci-black;
      }
      @include responsive(tablet){
        width: calc((100% / 12));
        height: calc((100% / 12));
      }
      & p{
        font-size: $root-font-size;
        font-family: Montserrat-semibold,sans-serif;
        text-align: center;
        line-height: 30px;
        position: absolute;
        transform: translate(-50%,-50%);
        top: 50%;
        left: 50%;
        @include responsive(tablet){
          font-size: 12px;
        }
      }
    }
  }
}

.ProductCreatedBy {
    margin-right: 7px;
    .icon{
        cursor: pointer;
        fill: rgb(255, 84, 84);
        width: 1em;
        height: 1em;
    }
}
//Setting all bubble background
.ToolTipPortal > div[style]{
    background-color: black !important;
}

//Setting arrow color
.ToolTipPortal > div span[style]{
    border-top: 10px solid black !important;
}
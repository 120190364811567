@import "../../../assets/styles/colors";
@import "../../../assets/styles/measures";
@import "../../../assets/styles/mediaqueries";

.FlightCards{
  border-radius: $element-borderRadius;
  box-shadow: $davinci-BoxShadow;
  background-color: $davinci-lightWhite;
  padding: $items-space/2;
  display: grid;
  display: -ms-grid;
  color: $davinci-white;
  grid-template-columns:5% 15% 10% 15% 15% 10% 20% 10%;
  -ms-grid-columns: 5% 15% 10% 15% 15% 10% 20% 10%;
  font-family: Montserrat-semibold,sans-serif;
  position: relative;

  @media screen and (max-width: 720px){
    display: block;
  }
  &:not(:last-child){
    margin-bottom: $items-space;
  }
 &-column{
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
 }
 &-delayTime{
     button {
         border:  none;
         background: transparent;
         cursor: pointer;
         color: white;
     }
 }
  &-statusIndicator{
    border-radius: 100px;
    width: 25px;
    height: 25px;
    background-color: $davinci-success;
    &.statusIndicatorFailed{
      background-color: #FF0000;
    }
  }
}

@import "../../../../../assets/styles/colors";
@import "../../../../../assets/styles/measures";

.ServiceAndExtraChargeAdder{
  background:$davinci-black-gradient;
  color:$davinci-black;
  border-radius: $element-borderRadius;
  padding: $items-space/2;
  display: flex;
  justify-content: space-around;

  .Button{
    display: inline-block;
    min-width: 140px;
  }
}
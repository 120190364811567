@import "../../assets/styles/colors";
@import "../../assets/styles/measures";
@import "../../assets/styles/mediaqueries";


.FixedMenu{
  position: absolute;
  display: flex;
  border-radius: 0 $element-borderRadius $element-borderRadius 0;
  z-index: 11;
  flex-direction: column;

  &.bottomBar{
    position: fixed;
    bottom: 0;
    left: 0;
    top: initial;
    width: 100%;
    flex-direction: row;
    background: $davinci-mainOrange;
    justify-content: space-around;
  }

  left: 0;
  top: 0;

  &-iconWrapper{
    background-color: $davinci-mainOrange;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:last-child{
      border-bottom-right-radius:  $element-borderRadius;
    }
    & svg{
      width: 30px !important;
      height: 30px;
      & path{
        color: black;
      }
    }

    & .red-alert svg{
      & path{
        color: red;
      }
    }
  }
}

@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/measures';

.CheckOutModal{

  text-align: center;

  &.charging{
    opacity: 0.2;
  }

  .SingleCheckInForm{
    text-align: left;
  }
  .second-message{
    margin-top: 20px;
  }

  .icons-container {
    border-radius: $element-borderRadius;
    border: 1px solid $davinci-mainOrange;
    display: block;
    width: 390px;
    padding: 30px;
    background: $davinci-grey;
    margin: 15px auto 30px;

    .center-arrow {
      height: 90px;
      width: 90px;
      margin: 0 50px;
      vertical-align: middle;

      path {
        fill: white;
      }
    }
    .icon {
      vertical-align: middle;
      font-size: 85px;
      margin: 0 30px;
    }
  }

  .Button{
    display: inline-block;
    font-size: 1.3em;
    margin: 40px 15px;
  }

  .Bill{
    max-width: 400px;
    margin: 30px auto;
  }

  .CommentBox-textArea{
      margin-top: $items-space;
      width: 95%;
  }

  .OtherHostDishes {
    border-radius: $element-borderRadius;
    border: 1px solid $davinci-mainOrange;
    display: block;
    margin: 15px 1rem 30px;
    padding: 1rem 0;
    width: 95%;

    .subTitle {
      padding: 0 0 1rem;
    }
    .list {
      padding: 0 4rem;

      .dish {
        display: flex;
        flex: 1;
        justify-content: space-around;
      }
    }
  }
}

@mixin responsive($breakpoint){
  @if $breakpoint == big-screen{
    @media only screen and (min-width: 1800px){@content};
  }
  @if $breakpoint == big-laptop{
    @media only screen and (max-width: 1700px){@content};
}

  @if $breakpoint == regular-laptop{
    @media only screen and (max-width: 1305px){@content};
}
  @if $breakpoint == laptop{
    @media (min-width: 1380px) and (max-width: 1700px){@content};
}

  @if $breakpoint == small-laptop{
    @media only screen and (max-width: 1305px) and (min-height: 910px){@content};
}
  @if $breakpoint == shortDevice{
  @media only screen and (max-height: 780px){@content}
}
  @if $breakpoint == smallAndShortDevice{
    @media only screen and (max-height: 800px) and (max-width: 1300px){@content}
  }

  @if $breakpoint == tablet{
    @media (max-width: 1025px){@content}
  }
}

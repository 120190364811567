@import "../../../../../../assets/styles/colors";
@import "../../../../../../assets/styles/measures";

.CheckInInfoCard{
  cursor: pointer;
  &:not(:last-child){
    margin-bottom: $items-space;
  }
  &-cardWrapper{
    border-radius: $element-borderRadius;
    box-shadow: $davinci-BoxShadow;
    background-color: $davinci-lightWhite;
    padding: $items-space/2;
    display: grid;
    display: -ms-grid;
    grid-template-columns:35% 25% 25% 15%;
    -ms-grid-columns:35% 25% 25% 15%;
    color: $davinci-white;
    font-family: Montserrat-semibold,sans-serif;
    position: relative;
    &.selected{
      background-color: $davinci-blue;
    }
  }
  &-column{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

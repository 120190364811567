@import 'src/assets/styles/colors';

.ErrorBoundary{
  display: flex;
  text-align: center;
  padding-top: 100px;
  .crash-img{
    max-width: 100%;
    animation-duration: 3s;
    animation-delay: 3s;
  }
  .sides{
    flex: 1;
  }
  .right{
    > * {
      max-width: 500px;
    }
    .title{
      color: $davinci-mainOrange;
      font-size: 2.4em;
    }
    .message{
      margin-top: 100px;
      font-size: 1.5em;
    }
    .but-container{
      margin-top: 50px;
    }
    .button{
      display: inline-block;
    }
  }
}

.LoungeMapModal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  color: white;

  .overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
  }

  .map-container{
    width: 80%;
    height: 90%;
    margin: 5vh auto 0;
    background-color: #00001e;
    border-radius: 20px;
    padding: 20px;
    position: relative;
    z-index: 2;

    .LoungeMap{
      height: 100%;
    }
  }
  &.with-buttons{
    .LoungeMap{
      height: calc(100% - 50px);
    }
    .buttons-container{
      display: flex;
      padding-top: 10px;
      justify-content: space-around;
      .Button{
        min-width: 140px;
      }
    }
  }

}

@import '../../assets/styles/colors';
@import '../../assets/styles/measures';

.ConfirmationModal{
  text-align: center;
  .Button{
    display: inline-block;
    font-size: 1.7em;
    margin: 50px;
  }
}
@import "../../assets/styles/colors";
@import "../../assets/styles/measures";

.Button{
  cursor: pointer;
  padding:$items-space/2;
  font-family: Montserrat-medium,sans-serif;
  border-radius: $element-borderRadius;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;

  &:active{
    transform: translateY(-1px);
  }

  &:focus{
    box-shadow: 0 0 2px 1px $davinci-mainOrange;
  }

  &.main{
    color: $davinci-white;
    background-color: transparent;
    border: 1px solid $davinci-mainOrange;
  }
  &.solid{
    background-color: $davinci-mainOrange;
    color: $davinci-black;
    border: none;
    font-size: $root-font-size*1.4;
    font-family: Montserrat-semibold,sans-serif;
  }
  &.blue{
    background-color: $davinci-blue;
    border-color: $davinci-blue;
  }

  &:disabled{
    cursor: not-allowed;
    opacity: 0.6;
  }
}

@import "../../assets/styles/colors";
@import "../../assets/styles/measures";

.container{
  display:flex;
  justify-content:center;
}
.che{
  display:none;
}
.radio-container{
  position:relative;
  border-radius:30px;
  background-color:transparent; /* #74D44C*/
  width:30px;
  height:10px;
  cursor:pointer;
  transition: all .5s;
  border: 1px solid $davinci-blue;
}
.radio-container::after{
  content:'';
  background-color:$davinci-blue;
  width:12px;
  height:12px;
  position:absolute;
  border-radius:50px;
  transform:translate(-50%,-50%);
  top:50%;
  left:10%;
  transition: all .3s;
  box-shadow:0 3px 5px rgba(0,0,0,.2);
}
.che:checked + .radio-container{
  background-color: $davinci-blue !important;
}
.che:checked + .radio-container:after{
  left:80%;
}


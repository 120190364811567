@import "../../assets/styles/colors";
@import "../../assets/styles/measures";
@import "../../assets/styles/mediaqueries";

.Balloons {
  height: 200px;
  width: 80px;
  position: absolute;
  z-index: 100;
  transform: translateY(150vh);
  animation: fly 4s linear 1;

  &-top {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 20%;
    border-radius: 50% 50% 50% 50% / 40% 40% 60% 60%;
    box-shadow: inset -5px -5px 0 rgba(0, 0, 0, 0.07),
    inset 0 0 4px 5px rgba(0, 0, 0, 0.03);
    height: 100px;
    width: 80px;
  }

  &-triangle {
    z-index: 100;
    position: absolute;
    transform: translate(0%, 0%);
    left: 45%;
    top: 42%;
    border-right: 4px solid transparent;
    border-bottom: 12px solid;
    border-left: 4px solid transparent;
    animation: swing 2s infinite ease;
    transition: border-bottom 1s;
  }

  &-rope {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 65%;
    left: 50%;
    display: block;
    width: 5px;
    height: 100px;
    margin-top: 12px;
    background: rgba(255, 255, 255, 0);


    & span {
      position: absolute;
      width: 3px;
      height: 3px;
      left: 60%;
      background: rgba(255, 255, 255, 1);
      opacity: 1;
      border-radius: 80px;
      animation: ropeSwing 1s infinite ease-in-out;

      &:nth-child(2) {
        top: 2px;
        animation-delay: 0.1s;
      }

      &:nth-child(3) {
        top: 4px;
        animation-delay: 0.2s;
      }

      &:nth-child(4) {
        top: 6px;
        animation-delay: 0.3s;
      }

      &:nth-child(5) {
        top: 8px;
        animation-delay: 0.4s;
      }

      &:nth-child(6) {
        top: 10px;
        animation-delay: 0.5s;
      }

      &:nth-child(7) {
        top: 12px;
        animation-delay: 0.6s;
      }

      &:nth-child(8) {
        top: 14px;
        animation-delay: 0.7s;
      }

      &:nth-child(9) {
        top: 16px;
        animation-delay: 0.8s;
      }

      &:nth-child(10) {
        top: 18px;
        animation-delay: 0.9s;
      }

      &:nth-child(11) {
        top: 20px;
        animation-delay: 1s;
      }

      &:nth-child(12) {
        top: 22px;
        animation-delay: 1.1s;
      }

      &:nth-child(13) {
        top: 24px;
        animation-delay: 1.2s;
      }

      &:nth-child(14) {
        top: 26px;
        animation-delay: 1.3s;
      }

      &:nth-child(15) {
        top: 28px;
        animation-delay: 1.4s;
      }

      &:nth-child(16) {
        top: 30px;
        animation-delay: 1.5s;
      }

      &:nth-child(17) {
        top: 32px;
        animation-delay: 1.6s;
      }

      &:nth-child(18) {
        top: 34px;
        animation-delay: 1.7s;
      }

      &:nth-child(19) {
        top: 36px;
        animation-delay: 1.8s;
      }

      &:nth-child(20) {
        top: 38px;
        animation-delay: 1.9s;
      }

      &:nth-child(21) {
        top: 40px;
        animation-delay: 2s;
      }

      &:nth-child(22) {
        top: 42px;
        animation-delay: 2.1s;
      }

      &:nth-child(23) {
        top: 44px;
        animation-delay: 2.2s;
      }

      &:nth-child(24) {
        top: 46px;
        animation-delay: 2.3s;
      }

      &:nth-child(25) {
        top: 48px;
        animation-delay: 2.4s;
      }

      &:nth-child(26) {
        top: 50px;
        animation-delay: 2.5s;
      }

      &:nth-child(27) {
        top: 52px;
        animation-delay: 2.6s;
      }

      &:nth-child(28) {
        top: 54px;
        animation-delay: 2.7s;
      }

      &:nth-child(29) {
        top: 56px;
        animation-delay: 2.8s;
      }

      &:nth-child(30) {
        top: 58px;
        animation-delay: 2.9s;
      }

      &:nth-child(31) {
        top: 60px;
        animation-delay: 3s;
      }

      &:nth-child(32) {
        top: 62px;
        animation-delay: 3.1s;
      }

      &:nth-child(33) {
        top: 64px;
        animation-delay: 3.2s;
      }

      &:nth-child(34) {
        top: 66px;
        animation-delay: 3.3s;
      }

      &:nth-child(35) {
        top: 68px;
        animation-delay: 3.4s;
      }

      &:nth-child(36) {
        top: 70px;
        animation-delay: 3.5s;
      }

      &:nth-child(37) {
        top: 72px;
        animation-delay: 3.6s;

        &:nth-child(38) {
          top: 74px;
          animation-delay: 3.7s;
        }

        &:nth-child(39) {
          top: 76px;
          animation-delay: 3.8s;
        }

        &:nth-child(40) {
          top: 78px;
          animation-delay: 3.9s;
        }

        &:nth-child(41) {
          top: 80px;
          animation-delay: 4s;
        }

        &:nth-child(42) {
          top: 82px;
          animation-delay: 4.1s;
        }

        &:nth-child(43) {
          top: 84px;
          animation-delay: 4.2s;
        }

        &:nth-child(44) {
          top: 86px;
          animation-delay: 4.3s;
        }

        &:nth-child(45) {
          top: 88px;
          animation-delay: 4.4s;
        }

        &:nth-child(46) {
          top: 90px;
          animation-delay: 4.5s;
        }

        &:nth-child(47) {
          top: 92px;
          animation-delay: 4.6s;
        }

        &:nth-child(48) {
          top: 94px;
          animation-delay: 4.7s;
        }

        &:nth-child(49) {
          top: 98px;
          animation-delay: 4.8s;
        }

        &:nth-child(50) {
          top: 100px;
          animation-delay: 4.9s;
        }
      }
    }
  }
}



@keyframes swing {
  0%,
  100% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(0);
  }
}
@keyframes fly {
  0% {
    transform: translateY(150vh) rotate(20deg);
  }

  100% {
    transform: translateY(-150vh) rotate(-30deg);
  }
}

@keyframes ropeSwing {
  0% {opacity: 1; transform:translateX(0px);}
  50% {opacity: 1; transform: translateX(-5px);}
  100%  {opacity: 1; transform:translateX(0px);}
}

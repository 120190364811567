@import "../../../../assets/styles/measures";
@import "../../../../assets/styles/colors";

.form-column.genderSelector{
  display: flex;
  align-items: center;
}

.womenOption,.menOption{
  display: none;
}
.womenLabel,.menLabel{
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg{
    position: absolute;
    transform: translate(0,-50%);
    top: 50%;
    left: 10px;
    width: 25px;
    height: 25px;
  }
}
.womenLabel{
  border-radius: $element-borderRadius 0 0 $element-borderRadius;
  transition: all .8s;
}
.menLabel{
  border-radius:  0 $element-borderRadius $element-borderRadius 0;
}
.PaymentFormOverlay-payment-method-creditCard.women,.PaymentFormOverlay-payment-method-cash.men{
  cursor: pointer;
}

.is-arrival-button{
    background-color: $davinci-mainOrange;
    border-radius: $element-borderRadius;
    border: 0px;
    color: $davinci-black;
    cursor: pointer;
    height: $burgerBtn-height;
    padding: 0 $items-space/2;
}

.womenOption:checked + .womenLabel{
  background-color: $davinci-mainOrange;
}
.womenOption:checked + .womenLabel > svg > *{
  fill: $davinci-white;
}
.menOption:checked + .menLabel > svg > *{
  fill: $davinci-white;
}
.menOption:checked + .menLabel{
  background-color: $davinci-mainOrange;
}

.GuestForm{
  .flight-type-row {
    align-items: flex-end;
    p{
      margin: 0;
    }
  }
  margin-bottom: $items-space;
}

.ReCheckInArrow{
    position: relative;
    &-container{
        position: absolute;
    }
    &-top-arrow{
        border-bottom: white 13px solid;
        border-left: transparent 13px solid;       
        border-right: transparent 13px solid;
        width: 0;
    }
    &-bottom-arrow{
        border-bottom: transparent 13px solid;
        border-left: white 13px solid;       
        border-right: white 13px solid;
        width: 0;
    }
}

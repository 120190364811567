@import "../../../../../assets/styles/colors";
@import "../../../../../assets/styles/measures";
@import "../../../../../assets/styles/mediaqueries";

.PendingBill{
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: $davinci-grey-gradient;
  padding: $items-space;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:$davinci-white;
  border-radius: $element-borderRadius;
  height: calc((100% / 3) - #{$items-space});

  &-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: $root-font-size*1.2;
    font-family: Montserrat-semibold,sans-serif;
    background-color: $davinci-grey-gradient;
    border: 1px solid $davinci-mainOrange;
    border-radius: $element-borderRadius;
    padding:$items-space/2;
    position: absolute;
    top: 20px;
    left: 50%;
    width: 90%;
    transform: translate(-50%, 0);
    &-input{
      height: 100%;
      color: $davinci-mainOrange;
      background-color: $davinci-grey-gradient;;
      font-size:  $root-font-size*1.2;
      font-family: Montserrat-medium,sans-serif;
      border: none;
      outline: none;
      cursor: pointer;
      padding-right: $items-space;
      &::placeholder{
        color: $davinci-mainOrange;
        font-family: Montserrat-semibold,sans-serif;
      }
      &:focus::placeholder{
        color: $davinci-lightWhite;
      }
    }
    & svg{
      position: absolute;
      right: 8px;
      pointer-events: none;
      & path{
        fill: $davinci-mainOrange;
      }
    }
  }
  &-noInfo{
    text-align: center;
    color: $davinci-mainOrange;
    font-size: 1.5rem;
  }
  &-content{
    overflow-y: scroll;
    margin-top: $items-space*2.5;
    padding-right: 1rem;
    width: 100%;
  }
  &-list{
    width: 100%;
    &-item{
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-size: $root-font-size/1.05;
      list-style: none;
      padding: $items-space/2 0 $items-space/5 $items-space/2;
      border-bottom:1px solid $davinci-mainOrange;
      &-timeAndVisit{
        display: flex;
        justify-content: space-between;
        & p:nth-child(2n){
          background-color: $davinci-mutedWhite;
          border-radius: 100px;
          width: 20px;
          height: 20px;
          text-align: center;
          line-height: 20px;
        }
        & p:not(:last-child){
          margin-right: $items-space/2;
        }
      }

      &:not(:last-child){
        border-bottom: 1px solid $davinci-mainOrange;
      }
    }
  }
}

@import '../../../assets/styles/measures';
@import '../../../assets/styles/colors';


.config-block{

  .config-content{
    font-size: 1.4em;


    .left, .right{
      display: inline-block;
      width: 50%;
      vertical-align: top;
    }
    .actual-name{
      margin-top: 20px;
      font-size: 1.4em;
    }
    .icon{
      padding: 20px;
      border-radius: $element-borderRadius;
      border: 1px solid $davinci-mainOrange;
      display: inline-block;
    }
    svg{
      font-size: 90px;
    }

    .bottom{
      margin-top: 20px;
      p{
        margin-bottom: 10px;
      }
    }
  }
}

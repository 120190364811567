@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/measures';

.ExtraChargeModal{

  .MenuFoodCard{
    width: 170px;
    height: 125px;
    display: inline-block;
  }

  .selected-products-list{
    list-style: none;
    margin-top: 15px;
    .selected-product-item{
      padding: 10px;
      background-color: $davinci-lightBlue;
      border-radius: $element-borderRadius;
      margin-bottom: 10px;
      overflow: auto;
      .quantity{
        display: inline-block;
        font-weight: bold;
        margin-right: 5px;
      }
      .prices{
        float: right;
      }
      .main-row{
          overflow: auto;
      }
      .remove-entry{
          background: none;
          border: none;
          color: crimson;
          font-weight: bold;
          font-size: 25px;
          cursor: pointer;
      }
    }
  }
  .notes-input{
      width: 100%;
  }
  .control-change::after{
      content: " ";
      display: block;
      height: 0;
      clear: both;
  }
  .change-price{
      float:right;
      text-decoration: underline;
      cursor: pointer;
  }
  .edit-price{
      float: right;
      input{
          border-radius: $element-borderRadius 0 0 $element-borderRadius;
      }
      select{
          border-radius: 0 $element-borderRadius $element-borderRadius 0;
      }
  }

  .buttons{
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    .Button{
      font-size: 1.6em;
      min-width: 6em;
    }

  }
}

@import "colors";
@import 'measures';
@import 'mediaqueries';

.App{
  position: relative;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar{
  border-radius: 10px;
  width:2px;
  height: 2px;
}
::-webkit-scrollbar-corner{
  background-color: transparent;
}
::-webkit-scrollbar-track{
  border-radius: 8px;
  background-color: transparent;
}
::-webkit-scrollbar-track-piece{
  background-color: transparent;
}
::-webkit-scrollbar-thumb{
  border-radius: 8px;
  background-color: rgba(255,255,255,.3);
}
html,body{
  font-family: Montserrat-medium, sans-serif;
  color: white;
  background-color: black;
}

b, strong{
  font-family: Montserrat-semibold, sans-serif;
  font-weight: 600;
}

// Reset bootstrap styles
a, a:hover{
  color: inherit;
  text-decoration: none;
}
p{
  margin: 0;
}

.davinci-input{
  border-radius: $element-borderRadius;
  background-color: $davinci-black;
  padding: $items-space/1.5;
  color: $davinci-white;
  border: none;
  outline: none;
  font-family: Montserrat-medium,sans-serif;
  text-transform: uppercase;
  &.no-upper{
    text-transform: none;
  }
}
.input-alert{
  color: $davinci-danger;
}

.full-width{
  width: 100%;
}
.full-height{
  height: 100% !important;
}
.half-width{
  width: 48%;
  @media screen and (max-width: 600px){
    width: 100%;
  }
}
.priced-icon {
  display: flex;
  justify-content: flex-end;
  max-width: 70px;
  width: 100%;
}
.cross{
  width: 20px;
  height: 1px;
  background-color: $davinci-white;
  transform: rotate(45deg);
  position: relative;
  &:after{
    content: '';
    position: absolute;
    width: 20px;
    height: 1px;
    background-color: $davinci-white;
    transform: rotate(90deg);
  }
  &-wrapper{
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    right: 0;
  }
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}
.toUpperCase{
  text-transform: uppercase;
}
.App.blurred{
  filter: blur(5px);
}

.text-center{
  text-align: center;
}

.main-content{
  height: calc(100vh - #{$topBar-height});
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
  margin: 0 auto;
  padding-bottom: $items-space*3;
  @include responsive(big-laptop){
    width: 95%;
  }
  @include responsive(tablet){
    flex-wrap: wrap;
  }
}

.red{
  color: red;
}

.green{
  color: greenyellow;
}

*.unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.version{
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: $davinci-mutedWhite;
  cursor: pointer;
  z-index: 15;
  &.update{
    color: #ff15a0;
  }
}
.toLowerCase{
  text-transform: lowercase;
}

.center{
  text-align: center;
}

.davinci-title{
  padding: 10px;
  margin-bottom: 10px;
  background-color: $davinci-blue;
  font-size: 24px;
  font-family: Montserrat-semibold,sans-serif;
  border-radius: 11px;
  color: #fff;
  text-align: center;
}

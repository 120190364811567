@import "../../../../assets/styles/colors";
@import "../../../../assets/styles/measures";
@import "../../../../assets/styles/mediaqueries";

.LoungeAvailable{
  overflow-y: scroll;
  border: 2px solid $davinci-mainOrange;
  border-radius: $element-borderRadius;
  background-color: black;
  position: absolute;
  top:$items-space *2;
  left:0;
  right: 0;
  z-index: 30;
  height: 10px;
  transition: height .3s;
  & p{
    margin: 0;
  }
  &.animateOpen{
    height: 300px;
  }
  &-availableBar{
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 125px;
    &-selectBox{
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      & p{
        margin-right: 10px;
      }
    }
    & p{
      margin-bottom: $items-space/2;
    }
  }
  &-list{
    width: 100%;
    &-item{
      width: 100%;
      padding: $items-space;
      color: $davinci-white;
      font-size: $root-font-size;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-itemSelectBox{
      width: 100%;
      color: $davinci-white;
      font-size: $root-font-size*.8;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &.selectable .lounge-item:hover{
    background: $davinci-black-gradient;
  }

}

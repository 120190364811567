@import "../../../../../assets/styles/colors";
@import "../../../../../assets/styles/measures";
@import "../../../../../assets/styles/mediaqueries";

.OrderResume{
  width: 100%;
  height: 90%;
  border-radius: $element-borderRadius;
  background: $davinci-black-gradient;
  padding: $items-space;
  position: relative;
  display: flex;
  flex-direction: column;
  .comment-btn{
    opacity: .4;
    cursor: pointer;
    margin-right: 10px;
    border: none;
    background-color: transparent;
    width: 20px;
    height: 20px;
    outline: none;
    &.dishWithNotes{
      opacity: 1;
    }
    & svg{
      width: 20px;
      height: 20px;
      & > *{
        fill: white;
        stroke: white;
      }
    }
  }
  .deleteDish{
    cursor: pointer;
    border: none;
    border-radius: 5px;
      display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: $davinci-danger;
    & svg {
      width: 20px;
      height: 20px;
      & > *{
        fill: white;
        stroke: white;
      }
    }
  }
  .kidImgWrapper{
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    background-color: white;
    opacity: .2;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    &.kidImgSelected{
      opacity: 1;
    }
  }
  .kidImg{
    width: 25px;
    height: 25px;
  }
  &-newAdvice{
    font-size: 14px;
  }
  .openComments{
    position: absolute;
    background-color: $davinci-blue;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 25px;
    border-radius: 10px 0 10px 0;
    outline: none;
    border:none;
    top:0;
    left: 0;
  }
  &-trashCan{
      width: 35px;
      height: 35px;
    cursor: pointer;
    & svg{
      & > *{
        fill: $davinci-mainOrange;
      }
    }
  }
  &-modifier{
    width: 100%;
    /*height: 150px;*/
    flex-wrap: wrap;
    justify-content: center;
    margin-top: $items-space;
    overflow-y: scroll;
    padding-left: $items-space/2;
    &.canceled{
      display: none;
    }
    &-item{
      border-radius: $element-borderRadius;
      border: 1px solid $davinci-mainOrange;
      width: 98%;
      min-height: 40px;
      /*height:50px;*/
      margin-bottom: $items-space/2;
      display: flex;
      justify-content: center;
      align-items: center;
      justify-self: center;
      & p{
        text-align: center;
      }
      .Check .Box{
        width: 100%;
        border: none;
        height: auto;
        min-height: 40px;
      }
    }
  }
  &-orderInfo{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
    .remove-button{
      background-color: $davinci-danger;
      border: none;
      width: 2rem;
      height: 2rem;
      color: white;
      border-radius: 5px;
    }
    .priced-icon{
      margin-right: 8px;
      &.red{
        color: red;
      }
    }
  }
  &-checkBox{
    width: 2rem;
    height: 2rem;
  }
  .inline-checkBox{
      display: flex;
      align-items: center;
      .CheckBoxContainer{
          width: auto;
      }
      p{
          margin-left: 5px;
        font-size: 14px;
      }
  }
  &-title{
    padding: $items-space/3;
    color: $davinci-white;
    font-size: $root-font-size*1.2;
    border-bottom: 2px solid $davinci-white;
    font-family: 'Montserrat-semibold',sans-serif;
    margin-bottom: $items-space;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  &-listWrapper{
    flex-grow: 1;
    overflow-y: scroll;
    overflow-x: scroll;
    margin-bottom: $items-space;
  }
  &-list{
    height: 80%;
    width: 100%;
    position: relative;
    padding-right: $items-space/2;
    .product-row{
      list-style:none;
      color: $davinci-white;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      padding: 5px;
      font-size: 1.2em;

      &.canceled{
        background-color:  $davinci-lightDanger;
        border-radius: 5px;
        margin: 5px 0;
        .product-desc{
          text-decoration: line-through;
        }
      }
      .product-desc-info{
        display: flex;
        font-size: $root-font-size*.8;
      }
      .product-desc{
        text-align: left;
        flex-grow: 1;
        padding: 0 20px;
        @include responsive(tablet){
          padding: 0 5px;
        }
        & p{
          width: 240px;
          white-space:nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @include responsive(big-laptop){
            width: 150px;
          }
        }
        @include responsive(big-laptop){
          font-size: $root-font-size;
        }
        .quantity{
          font-weight: bold;
          margin-right: 5px;
        }
      }
      &.opacity{
        opacity: .5;
      }

    }
  }
  .context-actions-buttons{
    min-height: 68px;
    .Button{
      display: inline-block;
      margin-bottom: 10px;
    }
  }
  .printAndCancel{
    min-height: 68px;
    display: flex;
    .Button{
      margin: 10px 5px;
    }
  }
  &-addItem{
    //margin-bottom: $items-space;
  }
  &-emptyList{
    color: $davinci-mutedWhite;
    font-size: $root-font-size*1.5;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    width: 100%;
    left: 50%;
    text-align: center;
  }
  .bottom-buttons-wrap{
    height: 130px;
    button {
      font-size: 1em;
    }
  }
  .Order-comments-history{
    position: relative;
    & li{
      list-style: none;
    }
    .crossIcon{
      position: absolute;
      width: 20px;
      top: 0;
      right: 10px;
      & svg{
        width: 20px;
        & > *{
          fill: white;
          stroke: white;
        }
      }
    }
    & ul{
      border-radius: $element-borderRadius;
      padding:   $items-space/2  $items-space;
      background-color: black;
      overflow-y: scroll;
      height: 150px;
      width: 100%;
      color: white;
      & li:not(:last-child){
        margin-bottom: 10px;
      }
    }
  }
  .Order-comments{
    height: 60px;
  }
}
.commentsModal-btn{
  padding: 20px;
  display: flex;
  margin-top: 60px;
  justify-content: space-between;
}
.Order-comments-wrapper{
  position: relative;
  .keyBoard-btn{
    border:none;
    background-color: transparent;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    right: 10px;
    cursor: pointer;
    outline: none;
    & svg {
      width: 100%;
      height: 100%;
      & > *{
        stroke: $davinci-mainOrange;
        fill: $davinci-mainOrange;
      }
    }
  }
}


@import 'src/assets/styles/colors';

.RadioSelector{
  border: 1px solid $davinci-mainOrange;
  border-radius: 11px;
  overflow: hidden;
  display: inline-block;

  .option-container{
    display: inline-block;
    border-right: 1px solid $davinci-mainOrange;
    text-align: center;
    transition: background-color 0.3s;
    &:last-child{
      border-right: none;
    }
    &.selected{
      background-color: $davinci-mainOrange;
      color: white;
    }
    label{
      padding: 10px;
      display: inline-block;
      cursor: pointer;
    }
    input{
      display: none;
    }
  }
}

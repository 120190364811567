@import "../../../../../assets/styles/colors";
@import "../../../../../assets/styles/measures";
@import "../../../../../assets/styles/mediaqueries";

.WaiterCard{
  width: 100%;
  min-height:60px;
  background-color: $davinci-lightBlue;
  border-radius: $element-borderRadius;
  padding: 5px;
  color: $davinci-white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $root-font-size*.85;
  text-align: center;
  cursor: pointer;
  position: relative;
  animation-duration: 500ms;


  &:not(:last-child){
    margin-bottom: $items-space;
  }

  &.selected{
    background-color: $davinci-mainOrange;
  }

  .badges-container{
    position: absolute;
    top: -12px;
    left: 0;
  }

  .badge{
    width: 22px;
    height: 22px;
    border-radius: 20px;
    line-height: 1.7;
    font-weight: bold;
    font-size: $root-font-size*.85;
    position: relative;
    float: left;
    margin-right: 5px;
    color: white;
    background-color: $davinci-blue;
  }


  &-highlight{
   border: 1px solid $davinci-mutedWhite;
  }
}
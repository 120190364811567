.BoardingPassReader{
  margin: 20px 0;
  .video-container{
    position: relative;
    .debug-btn{
      position: absolute;
      top: 5px;
      right: 5px;
      border: none;
      background: none;
    }
    .debug-window{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0,0,0,0.5);
      text-align: left;
      padding: 10px;
    }
    .data-point{
      position: absolute;
      background-color: #4BB543;
      width: 5px;
      height: 5px;
    }
  }
}

@import "../../../../../assets/styles/colors";
@import "../../../../../assets/styles/measures";
@import "../../../../../assets/styles/mediaqueries";

.CommentBox{
  height: calc(46% - 103px);
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include responsive(big-laptop){
    height: calc((100% / 2.34) - 85px);
  }
  @include responsive(regular-laptop){
    height: calc((100% / 2.38) - 83px);
  }
  @include responsive(small-laptop){
    height: calc((100% / 2.34) - 82px);
  }
  &-textArea{
    margin-bottom: $items-space;
    border-radius: $element-borderRadius;
    resize: none;
    font-family: Montserrat-medium,sans-serif;
    font-size: $root-font-size/1.05;
    color: $davinci-white;
    background-color: $davinci-black;
    padding:$items-space;
    outline: none;
    height: 85%;
  }
}

@import "src/assets/styles/measures";

.RemoteTopBar{
  display: flex;
  padding: $items-space;
  justify-content: space-between;
  z-index: 1;
  position: relative;
  @media screen and (max-width: 600px){
    flex-direction: column-reverse;
    &>div:not(:first-child){
      margin-bottom: $items-space;
    }
  }
  .center-widgets{
    display: flex;
    justify-content: space-around;
    min-width: 30%;
  }
  .availability-container{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .TopBar-disponibilidad{
    justify-content: center;
    position: relative;
  }
}

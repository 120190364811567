@import "../../../../../assets/styles/colors";
@import "../../../../../assets/styles/measures";
@import "../../../../../assets/styles/mediaqueries";

.HostName{
  width: 100%;
  background-color: $davinci-mutedWhite;
  padding: $items-space;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #060607;
  border-radius: 11px;
  height: calc((100% / 8) - 20px);
  margin-bottom: 20px;
  position: relative;
  text-align: center;
  p{
    margin: 0;
    padding: 2px;
    color: white;
    @include responsive(tablet){
      font-size: $root-font-size*1.5;
    }
  }

  &-text{
    font-family: 'Montserrat-semibold',sans-serif;
    font-size: 1em;
    color: $davinci-white;
    letter-spacing: 1px;
    @include responsive(smallAndShortDevice){
      font-size: 10px;
    }
  }
}

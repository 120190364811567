@import "../../../../../assets/styles/colors";
@import "../../../../../assets/styles/measures";
@import "../../../../../assets/styles/mediaqueries";

.FlightNumber{
  cursor: pointer;
  width: 100%;
  background-color: $davinci-mutedWhite;
  padding: $items-space;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #060607;
  border-radius: 11px;
  height: calc((100% / 8) - 20px);
  margin-bottom: 20px;
  position: relative;
  transition: all .5s;
  &:hover{
    background-color: $davinci-lightBlue;
  }
&-planeImg{
  width: 50px;
  height: 40px;
  &.isEditingFlight{
    display: none;
  }
  @include responsive(regular-laptop){
    width: 35px;
    height: 30px;
  }
  @include responsive(big-laptop){
    width: 30px;
    height: 25px;
  }
}
  &:hover &-planeImg{
    display: none;
  }
  &:hover &-planeImg.isEditingFlight{
    display: block;
  }
  &-text{
    font-family: 'Montserrat-semibold',sans-serif;
    font-size: $root-font-size*1.4;
    color: $davinci-white;
    letter-spacing: 1px;
    @include responsive(regular-laptop){
      font-size: $root-font-size;
    }
    @include responsive(big-laptop){
      font-size: $root-font-size*.9;
    }
  }
  &-status{
    width: 25px;
    height: 25px;
    border-radius: 100px;
    &.problem{
      background-color: $davinci-danger;
    }
    &.noProblem{
      background-color: $davinci-success;
    }

  }
}

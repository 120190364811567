@import "../../../../../assets/styles/colors";
@import "../../../../../assets/styles/measures";
@import "../../../../../assets/styles/mediaqueries";

.Bill{
  width: 100%;
  background-color: $davinci-mutedWhite;
  padding: $items-space;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #060607;
  border-radius: 11px;
  height: calc((100% / 8) - 20px);
  margin-bottom: 20px;
  position: relative;

  &-text{
    font-family: 'Montserrat-semibold',sans-serif;
    font-size: $root-font-size*2;
    color: $davinci-white;
    letter-spacing: 1px;
    text-align: center;
  }

  .bill-amount{
    cursor: pointer;

    .charge-text{
      font-size: 0.6em;
      display: none;
      line-height: 0.8;
    }
    &:hover .charge-text{
      display: block;
    }
  }

  .dollar-icon{
    position: relative;
    left: -8px;
    top: 4px;
  }

  .flag-btn{
    position: absolute;
    background: none;
    border: none;
    outline: none;
    top: 35px;
    transition: left 0.3s, top 0.3s, width 0.3s, height 0.3s;
    cursor: pointer;
    @include responsive(smallAndShortDevice){
      top: 20px;
    }

    svg{
      width: 26px;
      height: 26px;
    }
    &.selected{
      left: calc(100% - 60px);
      top: 12px;
      svg{
        width: 45px;
        height: 45px;
      }
    }
    &.flag-1{
      left: 10px;
    }
    &.flag-2{
      left: 45px;
    }
  }
}

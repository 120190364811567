@import "../../assets/styles/colors";
@import "../../assets/styles/measures";
@import "../../assets/styles/mediaqueries";


.ProgressBar {
  &-loadingTimer{
    display: flex;
    align-items: center;
    justify-content: center;
    &.loungeProgressBar{
      border: 1px solid $davinci-mainOrange;
      border-radius: 10px;
    }
    &:not(:last-child){
      margin-bottom: $items-space/2;
    }
  }
  &-progress {
    border-radius: 100px;
    background-color: transparent;
    height: $loadingBar-height/3;
    -moz-appearance: none;
    appearance: none;
  &.customWidth{
    width: 90%;
  }
    &-icon{
      width: 12px;
      height: 12px;
      margin-right: $items-space;
    }
    &::-moz-progress-bar {
      background-color: transparent;
      border-radius: 100px;
    }
    &.hasWarning::-moz-progress-bar{
      background-color: $davinci-danger;
    }
    &.fullTime::-moz-progress-bar{
      background-color: $davinci-success;
    }
    &.mediumTime::-moz-progress-bar{
      background-color: $davinci-mainOrange;
    }

    &::-webkit-progress-bar {
      border-radius: 100px;
      background-color: transparent;
    }

    &.hasWarning::-webkit-progress-bar{
      background-color: transparent;
      border: 1px solid $davinci-danger;
    }
    &.fullTime::-webkit-progress-bar{
      background-color: transparent;
      border: 1px solid $davinci-success;
    }
    &.mediumTime::-webkit-progress-bar{
      background-color: transparent;
      border: 1px solid $davinci-mainOrange;
    }

    &::-webkit-progress-value {
      background-color: $davinci-mainOrange;
      border-radius: 100px;
    }

    &.hasWarning::-webkit-progress-value{
      background-color: $davinci-danger;
    }
    &.fullTime::-webkit-progress-value{
      background-color: $davinci-success;
    }
    &.mediumTime::-webkit-progress-value{
      background-color: $davinci-mainOrange;
    }

    &.hasWarning{
      background-color: transparent;
    }
    &.fullTime{
      background-color: transparent;
    }
    &.mediumTime{
      background-color: transparent;
    }
  }
}
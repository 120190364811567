.CloseRegisterModal{
  text-align: center;

  .icons-container {
    border-radius: 11px;
    border: 1px solid #FF8902;
    display: inline-block;
    padding: 30px;
    background: #454445;
    margin: 15px 0 30px;
    .icon{
      vertical-align: middle;
      font-size: 85px;
      margin: 0 30px;
    }
  }
  .message{
    font-size: 1.6em;
  }

  .amounts{
    margin: 50px auto;
    text-align: left;
    max-width: 550px;
  }
  .Button{
    display: inline-block;
    font-size: 1.7em;
    margin: 0 50px 25px 50px;
  }
}
@import '../../assets/styles/colors';
@import '../../assets/styles/measures';

.Login{
  background-color: $davinci-black;
  display: flex;
  width: 100vw;
  height: 100vh;
  &-left{
    width: 50%;
    background-color: $davinci-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    &-img{
      width: 323.42px;
      height: 392.5px;
    }
  }
  &-right{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 800px){
    .left {
      display: none;
    }
    &-right {
      width: 100%;
    }
  }

  &-wrapper{
    height: 556px;
    width: 352px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & form{
      border: 1px solid $davinci-blue;
      border-radius: $element-borderRadius;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $items-space;
      position: relative;
    }
  }
  &-title{
    color: $davinci-white;
    font-size:$root-font-size*1.8;
    font-family: 'Montserrat-semibold',sans-serif;
    margin-bottom: $items-space*2;
  }
  &-input{
    background-color: transparent;
    color: $davinci-blue;
    border: none;
    border-bottom: 1px solid $davinci-white;
    padding:$items-space/2;
    font-size: $root-font-size;
    margin-bottom: $items-space;
    font-family: 'Montserrat-medium',sans-serif;
    &::placeholder{
      font-family: 'Montserrat-medium',sans-serif;
      color: $davinci-mutedWhite;
    }
  }
  &-status{
    color: $davinci-white;
    margin-top: $items-space;
    margin-bottom: $items-space;
  }
  &-keyWrapper{
    width: 100%;
    padding-left: 20px;
  }
  &-keyRow{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    &:not(:last-child){
      margin-bottom: $items-space/2;
    }
  }
  &-key{
    border: 1px solid $davinci-mainOrange;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% / 3 - #{$items-space});
    height: 50px;
    border-radius: $element-borderRadius;
    background-color: transparent;
    color: $davinci-white;
    outline: none;
    &:active{
      transform: scale(.95);
    }
    & p{
      font-size: $root-font-size*2;
    }
    &:not(:nth-child(3n)){
      margin-right: $items-space;
    }
    &-backSpace{
    width: 200px;
      margin-right: 0 !important;
      & svg{
        & path{
          fill: $davinci-white;
        }
      }
    }
  }

}

.AttendingVisitMap{
  width: 90%;
  margin: auto;
  display: flex;
  height: 100%;

  .AttendingVisits-name{
    height: 55px;
  }
  .LoungeMap{
    height: calc( 100vh - 110px);
    width: 85%;
    &.with-visits{
      width: 70%;
    }
  }

  .davinci-title {
    position: relative;
  }
}

@import "src/assets/styles/measures";

.SelectDateTimePicker{
  display: flex;
  justify-content: space-around;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    .time-portion {
      margin-top: $items-space;
    }
  }

  .date-portion, .time-portion{
    display: flex;
    align-items: center;
    min-width: 30%;
    select{
      flex: 1;
    }
  }
  .time-points{
    font-size: 2em;
  }
  .right{
    border-radius: 0 $element-borderRadius $element-borderRadius 0;
  }
  .center{
    border-radius: 0;
  }
  .left{
    border-radius: $element-borderRadius 0 0 $element-borderRadius;
  }
}

@import "../../assets/styles/colors";
@import "../../assets/styles/measures";
@import "../../assets/styles/mediaqueries";

.CardButton{
  background-color: $davinci-lightBlue;
  border-radius: $element-borderRadius;
  padding: 5px;
  color: $davinci-white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $root-font-size*.85;
  text-align: center;
  cursor: pointer;
  position: relative;
  animation-duration: 500ms;
  width: 15%;
  margin-right: 20px;
  min-height: 80px;
  float: left;
  margin-top: 0;
  margin-bottom: $items-space;
  .text{
    font-size: $root-font-size*2.5;
  }
}
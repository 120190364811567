@import "../../../../../assets/styles/colors";
@import "../../../../../assets/styles/measures";
@import "../../../../../assets/styles/mediaqueries";

.MenuFoodCard{
  height: 100px;
  width: calc((100% / 6) - #{$items-space});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: $element-borderRadius;
  margin-bottom: $items-space;
  margin-right: $items-space;
  padding: 3px;
  overflow: hidden;
  box-shadow: $davinci-insetBoxShadow;
  position: relative;
  background-color: $davinci-lightWhite;
  cursor: pointer;
  user-select: none;
  @include responsive(tablet){
    width: calc((100% / 2) - #{$items-space});
    &:nth-child(2n){
      margin-right: 0;
    }
  }
  & p{
    color: $davinci-white;
    font-size: 0.9em;
    font-family: 'Montserrat-semibold',sans-serif;
    line-height: 1em;
  }

  .add-button{
    position: absolute;
    right: -2px;
    border-radius: 100px;
    height: 40px;
    width: 40px;
    background-color: $davinci-lightSuccess;
    font-size: 24px;
    text-align: center;
    padding: 3px 0 0 0;
    transition:bottom .2s;
    bottom: -100px;
    border: none;
    color: white;
    &.open{
      bottom: -2px;
    }
  }
  .remove-button{
    position: absolute;
    right: -2px;
    border-radius: 100px;
    height: 40px;
    width: 40px;
    background-color: $davinci-lightDanger;
    font-size: 24px;
    text-align: center;
    padding: 3px 0 0 0;
    transition:bottom .2s;
    bottom: -100px;
    border: none;
    color: white;
    &.open{
      bottom: -2px;
    }
  }

  .money-sign{
    position: absolute;
    top: -7px;
    right: -5px;
    font-size: 60px;
    opacity: 0.5;
    &.red{
      color:red;
    }
  }
}

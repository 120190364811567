@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/measures';

.LoungeTransferModal{
  text-align: center;

  .icons-container {
    border-radius: $element-borderRadius;
    border: 1px solid $davinci-mainOrange;
    display: inline-block;
    padding: 30px;
    background: $davinci-grey;
    margin: 15px 0 30px;

    .center-arrow {
      height: 90px;
      width: 90px;
      margin: 0 50px;
      vertical-align: middle;

      path {
        fill: white;
      }
    }

    .icon {
      vertical-align: middle;
      font-size: 85px;
    }
  }
  p.question{
    margin: 15px;
  }
  .Button{
    display: inline-block;
    font-size: 1.7em;
    margin: 50px;
  }
}

@import "../../../assets/styles/colors";
@import "../../../assets/styles/measures";
@import "../../../assets/styles/mediaqueries";

.WaiterMenu{
  width:100vw;
  height: 100vh;
  &-content{
    height: calc(100vh - #{$topBar-height});
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-bottom:$items-space;
  }
  &-left{
    width: 70%;
    height: 100%;
    @include responsive(tablet){
      width: 55%;
    }
  }
  &-right{
    width: 27%;
    height: 100%;
    @include responsive(tablet){
      width: 43%;
    }
  }
  &-visitGuestName{
    width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include responsive(tablet){
      width: 200px;
    }
  }
  &-name,&-orderResumyTitle{
    width: 100%;
    margin-bottom: $items-space;
    background-color: $davinci-blue;
    font-size: $root-font-size*1.5;
    font-family: Montserrat-semibold,sans-serif;
    border-radius: $element-borderRadius;
    color: $davinci-white;
    text-align: center;
    padding: 12px;
    position: relative;
    .Button{
      float: right;
      border: 1px solid white;
      position: absolute;
      top: 8px;
      right: 8px;
      font-size: 0.8rem;
      @include responsive(tablet){
        top: 50%;
        transform: translate(-50%, -50%);
        right: -45px;
        padding: 5px;
        width: 95px;
      }
    }
    .back-arrow-btn{
      background: none;
      border: none;
      position: absolute;
      top: 2px;
      left: 8px;
      svg{
        width: 50px;
        height: 50px;
        path{
        fill: white;
        }
      }
    }
  }
  &-name{
    display: flex;
    justify-content: center;
  }

  &-cards{
    overflow-y: scroll;
    width: 100%;
    height: 90%;
    display: flex;
    flex-wrap: wrap;
    align-content:flex-start;
    padding: 0 10px 0 12px;
    @include responsive(tablet){
      justify-content: space-between;
    }
  }

  .NumericKeyBoard-wrapper{
    position: absolute;
    border-radius: 10px;
    transform: translate(-50%,100%);
    left: 50%;
    bottom: 0;
    width: 250px;
    background-color: $davinci-grey-gradient;
    z-index: 1000;
  }
}
.shouldPayModal-btn{
  padding: 20px;
  display: flex;
  margin-top: 60px;
  justify-content: space-between;
}

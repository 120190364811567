@import "../../assets/styles/colors";
@import "../../assets/styles/measures";
@import "../../assets/styles/mediaqueries";
.KeyBoardFull{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 50vh;
  color: white;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow:0 -2px 5px rgba(255,255,255,.2);
  @include responsive(shortDevice){
    height: 63vh;
  }
  div, button{
    color: white;
  }

  &-container {
    z-index: 2;
    background-color: black;
    padding: $items-space*2;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include responsive(big-laptop){
      padding: 15px;
    }
  }
}

@import "../../../assets/styles/colors";
.EditDelayTimeModal {
    .time-picker{
        display: flex;
        justify-content: center;
        .time-col{
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
        }
        .time-col *{
            margin-bottom: 8px;
        }
        button {
            border-radius: 50%;
            border: 2px solid $davinci-mainOrange;
            font-size: 1.5rem;
            color: $davinci-mainOrange;
            background-color: transparent;
            width: 30px;
            height:30px;
            padding: auto auto;
            vertical-align: center;
        }
    }
    .control-container{
        width: 50%;
        margin: 10px auto;
    }
}

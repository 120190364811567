@import "../../assets/styles/colors";
@import "../../assets/styles/measures";
@import "../../assets/styles/mediaqueries";

.Counter{
  width: 80%;
  height: 38px;
  display: flex;
  border-radius: 100px;
  align-self: center;
  justify-self: center;
  @include responsive(laptop){
    width: 65%;
    height: 65%;
  }
  @include responsive(big-laptop){
    width: 70%;
    height: 30px;
  }
  &-plus,&-minus,&-text{
    justify-content: center;
    align-items: center;
    font-size: $root-font-size*1.5;
    font-family: 'Montserrat-semibold',sans-serif;
    color: $davinci-white;
    width:33.33333%;
    height: 100%;
    & button, & p{
      border: none;
      background-color:$davinci-lightOrange;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: $root-font-size*1.1;
      font-family: 'Montserrat-semibold',sans-serif;
      color: $davinci-white;
      width: 100%;
      outline: none;
    }
  }
  &-text{
    background-color: $davinci-mainOrange;
    border-radius: 100px;
  }
  &-plus{
    & button{
      cursor: pointer;
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
    }
  }
  &-minus{
    & button{
      cursor: pointer;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
    }
  }
}
